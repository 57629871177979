import React, { useState } from "react";
import ReactLoading from "react-loading";
import { Routes, Route, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { RequirementsForm } from "./Requirement";
import { INSURANCE_REQUIREMENT_QUERY } from "./queries";
import { DELETE_INSURANCE_REQUIREMENT } from "./mutations";
import PageHeader from "../../../components/PageHeader";
import { startAndEnd } from "../../../utils/truncate";

Modal.setAppElement("#root");

let contentStyle = {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )",
  overflow: "hidden",
};

let modalStyles = {
  content: contentStyle,
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const DeleteRequirementButton = (props) => {
  return (
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        props.setDeleteRequirementModalValues({
          requirementDeleteModalIsOpen: true,
          requirement: props.node.publicId,
          name: props.node.name,
        });
      }}
      className="outline-0 pointer br2 ba b--black-20 bg-white pv2 ph3 ml1 f7 lh-title bg-animate border-box"
    >
      <img className="dib v-btm w1" src="/trash.svg" alt="Delete" />
    </button>
  );
};

function DeleteRequirementModal(props) {
  const [deleteRequirement, { loading, error, reset }] = useMutation(
    DELETE_INSURANCE_REQUIREMENT,
    {
      onCompleted: () => {
        if (props.refetchRequirements) {
          props.refetchRequirements();
        }
        props.closeModal();
        reset();
      },
    }
  );

  const renderLoading = () => (
    <ReactLoading type={"spin"} color={"#cccccc"} className="center" />
  );

  const renderError = () => (
    <div className="br2 f6 flex items-center justify-center pa3 bg-lightest-blue navy">
      <span className="lh-title ml3">
        There was an error deleting the insurance requirement. Please try again.
      </span>
    </div>
  );

  const renderDeleteConfirmation = (props) => (
    <div className="roboto">
      <PageHeader title={"Delete Insurance Requirement Confirmation"} />
      {error ? renderError() : null}
      <div className="f6 fw5 db mb2 mt3">
        Are you sure you want to delete <b className="red">{props.name}</b>?
        This operation can't be undone.
      </div>
      <div className="mv4">
        <button
          className="f6 link dim br2 ph3 pv2 mb2 ml2 dib white bg-washed-red b--light-red red ba pointer fr"
          type="submit"
          onClick={(e) => {
            const insuranceRequirement = props.requirement;
            deleteRequirement({
              variables: { publicId: insuranceRequirement },
            });
          }}
        >
          Yes – delete this requirement!
        </button>
        <button
          onClick={(e) => {
            props.closeModal();
            reset();
          }}
          className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer fr b--black-10"
          type="submit"
        >
          Cancel
        </button>
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={(e) => {
        props.closeModal();
        reset();
      }}
      style={modalStyles}
      contentLabel="Delete Insurance Requirement"
    >
      {loading ? renderLoading() : renderDeleteConfirmation(props)}
    </Modal>
  );
}

function TableShell(props) {
  return (
    <table className="w-100 ba b--light-gray collapse ph2 mt2 mb4">
      <thead>
        <tr className="bb bw1 b--black-10">
          <th className="tl pv3 ph2 fw5 f7 ttu w-20">Created on</th>
          <th className="tl w-50 ph2 fw5 f7 ttu">Name</th>
          <th className="tl pv3 fw5 f7 ttu">Job Category</th>
          <th className="tl pv3 ph2 fw5 f7 ttu tc w-10"></th>
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </table>
  );
}

function InsuranceRequirementsTable(props) {
  const {
    fetchInsuranceRequirements,
    requirementsLoading: externalRequirementsLoading,
    requirementsData: externalRequirementsData,
    refetchRequirements: externalRefetchRequirements,
    createNewRequirementLink,
  } = props;

  const navigate = useNavigate();

  const [deleteRequirementModalValues, setDeleteRequirementModalValues] =
    useState({
      requirementDeleteModalIsOpen: false,
      requirement: "",
      name: "",
    });

  // Use internal useQuery only if external data is not provided
  const {
    loading: queryLoading,
    data: queryData,
    refetch: queryRefetch,
  } = useQuery(INSURANCE_REQUIREMENT_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !!(fetchInsuranceRequirements || externalRequirementsData),
  });

  const requirementsData =
    externalRequirementsData || queryData?.insuranceRequirements || [];
  const requirementsLoading = externalRequirementsLoading || queryLoading;
  const refetchRequirements = externalRefetchRequirements || queryRefetch;

  function renderJobCategoryPills(jobCategories) {
    if (!jobCategories || jobCategories.length === 0) {
      return (
        <span className="br3 mh1 mb1 pa1 bg-washed-blue ba b--blue blue">
          Default all
        </span>
      );
    }

    return jobCategories.map((category, index) => (
      <span
        key={index}
        className="br3 mh1 mb1 pa1 bg-washed-blue ba b--blue blue"
      >
        {startAndEnd(category?.node?.className)}
      </span>
    ));
  }

  const renderRows = () => {
    return requirementsData.map((requirement, index) => (
      <tr
        key={requirement.publicId}
        onClick={() => {
          navigate(requirement.publicId, { state: requirement });
        }}
        className="bb b--light-gray hover-bg-washed-blue pointer"
      >
        <td className="pv3 ph2 f7">
          {moment(requirement.created).format("MMM D, YYYY")}
        </td>
        <td className="pv3 ph2 f7">{requirement.name}</td>
        <td className="pv3 ph2 f7 flex flex-wrap">
          {renderJobCategoryPills(requirement?.jobCategories?.edges)}
        </td>
        <td className="pv3 ph2 f7 tc">
          <DeleteRequirementButton
            setDeleteRequirementModalValues={setDeleteRequirementModalValues}
            node={requirement}
            name={requirement.name}
          />
        </td>
      </tr>
    ));
  };

  const renderTableHeader = () => {
    const newRequirementUrl =
      createNewRequirementLink || "/organization/requirements/new";

    return requirementsData && requirementsData.length > 0 ? (
      <div className="flex justify-end">
        <button
          className="outline-0 pointer br2 ba b--black-20 pa2 ml1 f7 lh-title border-box bg-white bg-animate hover-bg-light-gray"
          onClick={() => navigate(newRequirementUrl)}
        >
          Create New Requirement
        </button>
      </div>
    ) : null;
  };

  const renderTable = () => {
    const newRequirementUrl =
      createNewRequirementLink || "/organization/requirements/new";

    if (!requirementsData || requirementsData?.length === 0) {
      return (
        <TableShell>
          <tr>
            <td colSpan={7}>
              <div className="flex flex-column items-center mv4">
                <div className="tc pa3 f6">
                  No insurance requirements found.
                </div>
                <div className="w-3 f6">
                  <button
                    className="outline-0 pointer br2 ba b--black-20 bg-blue white pa2 ml1 f7 lh-title border-box"
                    onClick={() => navigate(newRequirementUrl)}
                  >
                    Create New Requirement
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </TableShell>
      );
    }

    return (
      <>
        {renderTableHeader()}
        <TableShell>{renderRows()}</TableShell>
        <DeleteRequirementModal
          openModal={() =>
            setDeleteRequirementModalValues({
              ...deleteRequirementModalValues,
              requirementDeleteModalIsOpen: true,
            })
          }
          closeModal={() =>
            setDeleteRequirementModalValues({
              ...deleteRequirementModalValues,
              requirementDeleteModalIsOpen: false,
            })
          }
          modalIsOpen={
            deleteRequirementModalValues.requirementDeleteModalIsOpen
          }
          name={deleteRequirementModalValues.name}
          requirement={deleteRequirementModalValues.requirement}
          refetchRequirements={refetchRequirements}
        />
      </>
    );
  };

  const loadingRow = () => (
    <tr>
      <td colSpan={7}>
        <ReactLoading type={"spin"} color={"#cccccc"} className="mv4 center" />
      </td>
    </tr>
  );

  const renderLoadingTable = () => {
    return (
      <>
        <TableShell>{loadingRow()}</TableShell>
      </>
    );
  };

  let table;

  if (requirementsLoading || !requirementsData) {
    table = renderLoadingTable();
  } else {
    table = renderTable();
  }

  return (
    <Routes>
      <Route index element={<div>{table}</div>} />
      <Route path=":requirementId" element={<RequirementsForm {...props} />} />
    </Routes>
  );
}

export default InsuranceRequirementsTable;
