import React from 'react';
import classNames from 'classnames';

function EditButton(props) {
  const { resetForm, errors, isLoading, isSubmitting, setIsActiveEdit } = props;

  const renderEdit = () => (
    <div className="absolute top-2 right-2">
      <button
        type="button"
        onClick={() => setIsActiveEdit(true)}
        className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box"
      >
        Edit
      </button>
    </div>
  );

  const renderCancelSave = () => {
    const isDisabled = Object.keys(errors).length > 0;
    return (
      <div className="absolute top-2 right-2">
        <button
          type="button"
          onClick={() => {
            setIsActiveEdit(false);
            resetForm();
          }}
          className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isDisabled || isLoading || isSubmitting}
          className={classNames(
            'fr outline-0 pointer br2 ba b--black-20 pa2 ml1 f7 lh-title border-box',
            { 'bg-blue white': !isDisabled },
            { 'bg-black-10 black': isDisabled }
          )}
        >
          {isLoading ? 'Saving...' : 'Save'}
        </button>
      </div>
    );
  };

  return props.isActiveEdit ? renderCancelSave() : renderEdit();
}

export default EditButton;
