import React from "react";
import { Field } from "formik";

import EntitySideNav from "./EntitySideNav";

function EntityMain(props) {
  const { isActiveEdit, data } = props;

  return (
    <div className="flex flex-column w-100">
      <div className="flex mb3">
        <div className="w-25 pr3">
          <EntitySideNav {...props} isActiveEdit={isActiveEdit} data={data} />
        </div>

        <div className="w-75">
          <h3 className="dark-gray bb b--black-10 mt0 pb3">
            Required Coverage
          </h3>
          <div className="gray f6 fw2 relative">
            {data.requiredCoverage && data.requiredCoverage.length > 0 ? (
              <ul>
                {data.requiredCoverage.map((coverage, index) => (
                  <li key={index}>{coverage}</li>
                ))}
              </ul>
            ) : (
              <div>No required coverage specified.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EntityMain;
