import React, { useState } from "react";
import Modal from "react-modal";

import PageHeader from "../../components/PageHeader";
import PDFUpload from "../../components/PDFUpload";

let contentStyle = {
  width: "640px",
  maxHeight: "90vh",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )",
  overflow: "hidden",
};

let modalStyles = {
  content: contentStyle,
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

function AddUnstructuredRequirementModal(props) {
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel={props.contentLabel}
    >
      <div className="roboto">
        <PageHeader title={props.title || "Upload Certificate of Insurance"} />
        <PDFUpload
          token={props.token}
          setSuccessMessage={props.setSuccessMessage}
          setErrorMessage={props.setErrorMessage}
          setContractor={props.setContractor}
          closeModal={props.closeModal}
          uploadOnly={props.uploadOnly}
          targetURL={props.targetURL}
          inputName={props.inputName}
          labelIdle={props.labelIdle}
          entityId={props.entityId}
          requirementId={props.requirementId}
          onComplete={props.onComplete}
        />
      </div>
    </Modal>
  );
}

export default AddUnstructuredRequirementModal;
