// general.jsx
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import EditButton from "./EditButton";
import EntityMain from "./EntityMain";

function GeneralTabContent(props) {
  const {
    data,
    isAdmin,
    isActiveEdit,
    setIsActiveEdit,
    updateEntityDetails,
    saving,
  } = props;

  const initialValues = data
    ? {
        id: data.publicId,
        name: data.name || "",
        additionalInsuredMsg: data.additionalInsuredMsg || "",
        address: {
          line1: data.address?.line1 || "",
          line2: data.address?.line2 || "",
          line3: data.address?.line3 || "",
          locality: data.address?.locality || "",
          region: data.address?.region || "",
          postalcode: data.address?.postalcode || "",
        },
      }
    : {};

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    address: Yup.object().shape({
      line1: Yup.string().required("Required"),
      locality: Yup.string().required("Required"),
      region: Yup.string().required("Required"),
      postalcode: Yup.string().required("Required"),
    }),
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        updateEntityDetails({
          variables: { entity: values },
        });
        setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <Form>
          {isAdmin && (
            <EditButton
              isLoading={saving}
              setIsActiveEdit={setIsActiveEdit}
              isActiveEdit={isActiveEdit}
              {...formikProps}
            />
          )}
          <EntityMain
            {...props}
            {...formikProps}
            isActiveEdit={isActiveEdit}
            data={data}
          />
        </Form>
      )}
    </Formik>
  );
}

export default GeneralTabContent;
