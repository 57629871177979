import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useValidUsername } from "../../hooks/useAuthHooks";
import { Username } from "../../components/AuthComponents";
import { AuthWrapperWithLogo } from "../../components/AuthWrapper";
import { AuthContext } from "../../contexts/authContext";
import { useLazyQuery } from "@apollo/client";
import { USER_ACCOUNT_ELIGIBILITY } from "../OrganizationPage/queries";

export default function RequestCode() {
  const { username, setUsername, usernameIsValid } = useValidUsername("");
  const [error, setError] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const [checkUserAccountEligibility] = useLazyQuery(USER_ACCOUNT_ELIGIBILITY);

  const isValid = !usernameIsValid || username.length === 0;

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const sendCodeClicked = async () => {
    setError("");

    try {
      const { data } = await checkUserAccountEligibility({
        variables: { email: username },
      });

      if (!data) {
        setError("An error occurred. Please try again.");
        return;
      }

      if (data && data.userAccountEligibility !== true) {
        setError("Your email is not among those invited.");
        return;
      }

      // If invited, proceed to send the code
      await authContext.sendCode(username);
      setResetSent(true);
    } catch (err) {
      setError("Unknown email");
    }
  };

  const emailSent = (
    <>
      <p className="f6 gray lh-copy pt3 pb4">
        {`Reset code sent to ${username}`}
      </p>
      <button
        className="pointer button-reset bg-animate bg-brand-pink white mt0 bn w-100 pa3 hover-white br2"
        disabled={isValid}
        onClick={() => navigate("/reset-password")}
      >
        Continue
      </button>
    </>
  );

  const sendCode = (
    <>
      <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
      {error ? <div className="brand-pink pb3 f6">{error}</div> : null}
      <button
        className="pointer button-reset bg-animate bg-brand-pink white mt0 bn w-100 pa3 hover-white br2"
        disabled={isValid}
        onClick={sendCodeClicked}
      >
        Continue
      </button>
      <div className="link pointer tc pv3 f6 fw5" onClick={() => navigate(-1)}>
        <span className="brand-pink">Return to sign in</span>
      </div>
    </>
  );

  return (
    <AuthWrapperWithLogo>
      <Helmet>
        <meta
          name="description"
          content="Sign in to the 1099Policy dashboard."
        />
        <title>Reset Password | 1099Policy</title>
      </Helmet>
      <fieldset id="login" className="ba b--transparent ph0 mh0 dark-gray">
        <legend className="f3 pt4 pb2 fw6">Reset your password</legend>
        <p className="f6 gray lh-copy">
          Enter the email address associated with your account and we'll send
          you a link to reset your password.
        </p>
        {resetSent ? emailSent : sendCode}
      </fieldset>
    </AuthWrapperWithLogo>
  );
}
