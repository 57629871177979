import React, { useState, useEffect } from "react";

import ReactLoading from "react-loading";
import Modal from "react-modal";

import { useQuery } from "@apollo/client";
import { Formik } from "formik";
import { gql, useMutation } from "@apollo/client";

import classNames from "classnames";

import PageHeader from "../components/PageHeader";
import MainWrapper from "../components/MainWrapper";
import { USER_QUERY } from "../dashboard/queries";

import QRCode from "qrcode";
import OtpInput from "react-otp-input";

import ChangePassword from "./auth/ChangePassword";

Modal.setAppElement("#root");

const AUTH_PROVISIONING_URI = gql`
  query AuthProvisioningUri {
    authProvisioningUri {
      uri
      backupCodes
    }
  }
`;

const GENERATE_OTP_PROVISIONING_URI = gql`
  mutation GenerateOTPProvisioningURI {
    generateOtpProvisioningUri {
      ok
      provisioningUri
      backupCodes
      message
    }
  }
`;

const VERIFY_OTP = gql`
  mutation VerifyOtp($code: String!, $action: String!) {
    verifyOtp(code: $code, action: $action) {
      ok
      message
      action
    }
  }
`;

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )",
    width: "25rem",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

function PasswordModal(props) {
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel="Change password"
    >
      <div className="roboto">
        <PageHeader title={"Change Password"} />
        <div className="mt4">
          <ChangePassword />
        </div>
      </div>
    </Modal>
  );
}

function EditButton(props) {
  const renderEdit = () => {
    return (
      <button
        id="editprofile"
        type="button"
        onClick={() => props.setIsActiveEdit(true)}
        className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box"
      >
        Edit
      </button>
    );
  };

  const renderCancelSave = () => {
    return (
      <>
        <button
          id="cancelledit"
          type="button"
          onClick={() => props.setIsActiveEdit(false)}
          className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box"
        >
          Cancel
        </button>
        <button
          id="saveedit"
          type="button"
          onClick={() => console.log("saved")}
          className="fr outline-0 pointer br2 ba b--black-20 bg-blue white pa2 ml1 f7 lh-title bg-animate hover-bg-dark-blue border-box"
        >
          Save
        </button>
      </>
    );
  };

  return props.isActiveEdit ? renderCancelSave() : renderEdit();
}

function ProfilePage() {
  const { loading, data } = useQuery(USER_QUERY);
  const QR_URL = useQuery(AUTH_PROVISIONING_URI);
  const provisioningUri = QR_URL?.data?.authProvisioningUri?.uri;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isActiveEdit, setIsActiveEdit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [code, setCode] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [backupCodes, setBackupCodes] = useState([]);

  useEffect(() => {
    if (QR_URL?.data?.authProvisioningUri?.backupCodes) {
      setBackupCodes(QR_URL.data.authProvisioningUri.backupCodes);
    }
  }, [QR_URL]);

  const generateQR = async (text) => {
    try {
      return await QRCode.toDataURL(text);
    } catch (error) {
      console.error("Error generating QR code:", error);
      return null;
    }
  };

  const [verifyOtp] = useMutation(VERIFY_OTP, {
    onCompleted: (data) => {
      setSubmitting(false);

      if (!data?.verifyOtp?.ok) {
        alert("Code isn't verified");
        return;
      }

      setCode("");

      if (data?.verifyOtp?.action == "enable") {
        alert("Two-factor authentication is enabled");
      }

      if (data?.verifyOtp?.action == "disable") {
        alert("Two-factor authentication is disabled");
        setQrCodeUrl("");
        setBackupCodes([]);
      }
    },
    refetchQueries: ["CurrentUser"],
  });

  const [generateOtpProvisioningUri] = useMutation(
    GENERATE_OTP_PROVISIONING_URI,
    {
      onCompleted: (data) => {
        setSubmitting(false);
        setBackupCodes(data?.generateOtpProvisioningUri?.backupCodes);

        generateQR(data?.generateOtpProvisioningUri?.provisioningUri).then(
          (dataUrl) => {
            setQrCodeUrl(dataUrl);
          }
        );
      },
    }
  );

  useEffect(() => {
    if (provisioningUri) {
      generateQR(provisioningUri).then((dataUrl) => {
        setQrCodeUrl(dataUrl);
      });
    }
  }, [provisioningUri]);

  const GenerateOTPProvisioningURIButton = (props) => {
    if (props.submitting) {
      return (
        <button className="button-reset bg-animate white mt0 bn ttu pa3 w-100 mb2 hover-white br2">
          <ReactLoading
            type={"spin"}
            color={"#cccccc"}
            height={20}
            width={20}
            className="center"
          />
        </button>
      );
    } else {
      return (
        <input
          className="outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box"
          value="Enable Two-factor Authentication"
          onClick={props.generateOTPProvisioningURIClicked}
          type="submit"
        />
      );
    }
  };

  const generateOTPProvisioningURIClicked = async () => {
    setSubmitting(true);
    generateOtpProvisioningUri();
  };

  const EnableTwoFAButton = (props) => {
    if (props.submitting) {
      return (
        <button className="button-reset bg-animate white mt0 bn ttu pa3 w-100 mb2 hover-white br2">
          <ReactLoading
            type={"spin"}
            color={"#cccccc"}
            height={20}
            width={20}
            className="center"
          />
        </button>
      );
    } else {
      return (
        <input
          className="outline-0 pointer br2 ba b--black-20 bg-blue white pa2 ml1 f7 lh-title bg-animate hover-bg-dark-blue border-box"
          value="Enable Two-Factor"
          onClick={props.enableTwoFAClicked}
          type="submit"
        />
      );
    }
  };

  const DisableTwoFAButton = (props) => {
    if (props.submitting) {
      return (
        <button className="button-reset bg-animate white mt0 bn ttu pa3 w-100 mb2 hover-white br2">
          <ReactLoading
            type={"spin"}
            color={"#cccccc"}
            height={20}
            width={20}
            className="center"
          />
        </button>
      );
    } else {
      return (
        <input
          className="outline-0 pointer br2 ba b--red bg-red white pa2 ml1 f7 lh-title bg-animate hover-bg-light-red border-box"
          value="Disable Two-Factor"
          onClick={props.disableTwoFAClicked}
          type="submit"
        />
      );
    }
  };

  const enableTwoFAClicked = async () => {
    setSubmitting(true);
    verifyOtp({
      variables: {
        code: code,
        action: "enable",
      },
    });
  };

  const disableTwoFAClicked = async () => {
    setSubmitting(true);
    verifyOtp({
      variables: {
        code: code,
        action: "disable",
      },
    });
  };

  const renderLoading = () => {
    return (
      <div className="vh-100 dt w-100">
        <div className="dtc v-mid tc">
          <ReactLoading type={"spin"} color={"#cccccc"} className="center" />
        </div>
      </div>
    );
  };

  const renderForm = () => {
    const {
      currentUser: { name, email = "" },
    } = data;

    return (
      <>
        <Formik
          initialValues={{ email: email, name: name }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <div>
              <form onSubmit={handleSubmit}>
                <header className="mb3">
                  <h2 className="fw3 dark-gray mt0 mb4">
                    Profile
                    <EditButton
                      setIsActiveEdit={setIsActiveEdit}
                      isActiveEdit={isActiveEdit}
                    />
                  </h2>
                </header>
                <div>
                  <span className="w-100">
                    <label htmlFor="email" className="f6 fw5 dib w-20">
                      Email
                    </label>
                    <input
                      name="email"
                      className={classNames(
                        "outline-0 pa2 w-30 f6 fw3",
                        { "ba b--black-10 br2": isActiveEdit },
                        { bn: !isActiveEdit }
                      )}
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      readOnly={!isActiveEdit}
                    />
                  </span>
                </div>
                <div className="pt3">
                  <span className="w-100">
                    <label htmlFor="name" className="f6 fw5 dib w-20">
                      Name
                    </label>
                    <input
                      name="name"
                      className={classNames(
                        "outline-0 pa2 w-30 f6 fw3",
                        { "ba b--black-10 br2": isActiveEdit },
                        { bn: !isActiveEdit }
                      )}
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      readOnly={!isActiveEdit}
                    />
                  </span>
                </div>
                <div className="pt4">
                  <span className="w-100">
                    <label htmlFor="password" className="f6 fw5 dib w-20">
                      Password
                    </label>
                    <button
                      id="openmodal"
                      type="button"
                      onClick={() => setModalIsOpen(true)}
                      className="outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box"
                    >
                      Change password
                    </button>
                  </span>
                </div>
                <hr className="mv5 bt b--black-10" />
                <div className="pt4">
                  <span className="w-100 flex">
                    <label htmlFor="password" className="f6 fw5 dib w-20">
                      Two-factor authentication
                    </label>
                    <div className="w-40">
                      {data.currentUser?.twoFaEnabled ? (
                        <div className="otp-area mb3">
                          <span className="pv0 mv0 f5 fw6 black db">
                            Enabled
                          </span>
                          <p className="f12 fw4 gray db lh-copy">
                            To disable two-factor authentication, enter the code
                            from your authenticator app.
                          </p>

                          <OtpInput
                            value={code}
                            onChange={setCode}
                            numInputs={6}
                            renderInput={(props) => <input {...props} />}
                          />

                          <div className="mt3">
                            <DisableTwoFAButton
                              disableTwoFAClicked={disableTwoFAClicked}
                              submitting={submitting}
                            />
                          </div>
                        </div>
                      ) : qrCodeUrl ? (
                        <div className="otp-area mb3">
                          <img src={qrCodeUrl} alt="QR Code" />

                          <p className="f5 fw4 gray db lh-copy">
                            Scan the QR code with your authenticator app (e.g.,
                            Google Authenticator) and then verify the code to
                            enable two-factor authentication.
                          </p>

                          <OtpInput
                            value={code}
                            onChange={setCode}
                            numInputs={6}
                            renderInput={(props) => <input {...props} />}
                          />

                          <div className="mv4">
                            <h4>Backup Codes</h4>
                            {Array.isArray(backupCodes) &&
                              backupCodes &&
                              backupCodes.map((code) => {
                                return <p className="f5 black db">{code}</p>;
                              })}
                          </div>
                          <div className="mt3">
                            <EnableTwoFAButton
                              enableTwoFAClicked={enableTwoFAClicked}
                              submitting={submitting}
                            />
                          </div>
                        </div>
                      ) : (
                        <GenerateOTPProvisioningURIButton
                          generateOTPProvisioningURIClicked={
                            generateOTPProvisioningURIClicked
                          }
                          submitting={submitting}
                        />
                      )}
                    </div>
                  </span>
                </div>
              </form>
            </div>
          )}
        </Formik>
        <PasswordModal
          closeModal={() => setModalIsOpen(false)}
          modalIsOpen={modalIsOpen}
        />
      </>
    );
  };

  return <MainWrapper>{loading ? renderLoading() : renderForm()}</MainWrapper>;
}

export default ProfilePage;
