import React, { useState } from "react";
import { Field } from "formik";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";
import { states } from "../../constants/states";

import classNames from "classnames";

function EntitySideNav(props) {
  const [idCopied, setIdCopied] = useState(false);
  const { isActiveEdit, data } = props;

  function afterHideTip() {
    setIdCopied(false);
  }

  return (
    <header className="fn fl-ns w-100-ns mb3">
      <div className="fl pb2 pr2">
        <Field
          name="name"
          placeholder="Entity Name"
          className={classNames("outline-0 f3 fw6 pv2", {
            "ba b--black-10 br2": isActiveEdit,
            "ba b--transparent": !isActiveEdit,
          })}
          type="text"
          autoComplete="off"
          readOnly={!isActiveEdit}
        />
      </div>

      <div>
        <div className="dark-gray f6 fw2">
          {isActiveEdit ? (
            <>
              <div className="mt1">
                <Field
                  name="address.line1"
                  placeholder="Line 1"
                  className="outline-0 f6 fw3 ba b--black-10 br2 pv2"
                />
              </div>
              <div className="mt1">
                <Field
                  name="address.line2"
                  placeholder="Line 2"
                  className="outline-0 f6 fw3 ba b--black-10 br2 pv2"
                />
              </div>
              <div className="mt1">
                <Field
                  name="address.line3"
                  placeholder="Line 3"
                  className="outline-0 f6 fw3 ba b--black-10 br2 pv2"
                />
              </div>
              <div className="flex">
                <div className="mt1">
                  <Field
                    name="address.locality"
                    placeholder="City"
                    className="flex-auto outline-0 f6 fw3 ba b--black-10 br2 pv2 mr2 pa2 ba br2"
                  />
                </div>
                <div className="mt1">
                  <Field
                    component="select"
                    name="address.region"
                    className="flex-auto outline-0 f6 fw3 ba b--black-10 br2 pv2 mr2 pa2 ba br2"
                  >
                    {states.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.abbreviation}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="mt1">
                  <Field
                    name="address.postalcode"
                    placeholder="Postal Code"
                    className="flex-auto outline-0 f6 fw3 ba b--black-10 br2 pv2 pa2 ba br2"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mt1">{data.address?.line1}</div>
              <div className="mt1">
                {data.address?.line2 && <div>{data.address.line2}</div>}
              </div>

              <div className="mt1">
                {data.address?.line3 && <div>{data.address.line3}</div>}
              </div>

              {data.address?.locality && (
                <div className="mt1">
                  {data.address?.locality} {data.address?.region}{" "}
                  {data.address?.postalcode}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <CopyToClipboard text={data.publicId} onCopy={() => setIdCopied(true)}>
        <div
          data-tip
          data-for="entity_id_copy"
          className="mv3 fw2 f6 gray ba br2 lh-copy dib pv1 ph2 b--black-10 pointer"
        >
          {data.publicId}
        </div>
      </CopyToClipboard>
      <ReactTooltip
        id="entity_id_copy"
        place="bottom"
        type="info"
        afterHide={afterHideTip}
        effect="solid"
      >
        <span>{idCopied ? "Copied!" : "Copy"}</span>
      </ReactTooltip>

      <div className="mt3 fw2 f6 gray lh-copy db pv1 b--black-10">
        Created on: {new Date(data.created).toDateString()}
      </div>
    </header>
  );
}

export default EntitySideNav;
