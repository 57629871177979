import { gql } from "@apollo/client";

export const UPDATE_ENTITY_DETAILS = gql`
  mutation UpdateEntityDetails($entity: EntityInput!) {
    updateEntityDetails(entity: $entity) {
      ok
      message
      entity {
        publicId
        name
        additionalInsuredMsg
        address {
          line1
          line2
          line3
          locality
          region
          postalcode
        }
      }
    }
  }
`;

export const UPDATE_ENTITY_LEVEL_INSURANCE_REQUIREMENTS = gql`
  mutation UpdateEntityLevelInsuranceRequirements(
    $entityId: String!
    $jobCategories: [String]
    $coverageRules: [CoverageRuleInput]
  ) {
    updateEntityLevelInsuranceRequirements(
      entityId: $entityId
      jobCategories: $jobCategories
      coverageRules: $coverageRules
    ) {
      ok
      insuranceRequirement {
        publicId
      }
    }
  }
`;
