import React, { useState, useEffect, Fragment } from "react";
import ReactLoading from "react-loading";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Formik, Field, FieldArray } from "formik";
import classNames from "classnames";
import { toCamelCase } from "../../../utils/camelCase";
import { startAndEnd } from "../../../utils/truncate";
import EditButton from "./EditButton";
import PillInput from "./PillInput";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {
  INSURANCE_REQUIREMENT_BY_ID_QUERY,
  INSURANCE_REQUIREMENT_JOB_CATEGORIES,
} from "./queries";
import { UPDATE_INSURANCE_REQUIREMENT } from "./mutations";
import { mapValuesToRules } from "../../CertificateReviewPage/helpers";
import {
  mapRulesToValues,
  getCoveragesFromRules,
  getAutoCoveragesFromRules,
} from "../../CertificateReviewPage/helpers";

const additionalInsured = {
  name: "",
  line1: "",
  line2: "",
  line3: "",
  locality: "",
  region: "",
  postalcode: "",
};

const AdditionalInsuredForm = (props) => {
  const {
    isActiveEdit,
    helperText,
    formWrapperStyling,
    inputWrapperStyling,
    labelStyling,
    editIndexDefault,
  } = props;

  // State to track which item is being edited
  const [editIndex, setEditIndex] = useState(editIndexDefault);

  useEffect(() => {
    if (!isActiveEdit) {
      setEditIndex(null);
    }
  }, [isActiveEdit]);

  function formatAddress(insured) {
    const { line1, line2, line3, locality, region, postalcode } = insured;
    return startAndEnd(
      `${line1}${line2 ? ", " + line2 : ""}${
        line3 ? ", " + line3 : ""
      }, ${locality}, ${region} ${postalcode}`,
      36
    );
  }

  const DEFAULT_HELPER_TEXT =
    "If the vendor is required to include additional named insureds in their certificate of insurance, list them out here.";

  return (
    <FieldArray name="additionalInsured">
      {({ push, remove }) => (
        <Fragment>
          <div className={formWrapperStyling || "w-100 flex items-start"}>
            <label className={labelStyling || "f6 fw5 dib w-20"}>
              Name & Address
              <span className="black-50 f7 db lh-copy pv2 pr1 measure-wide">
                {helperText || DEFAULT_HELPER_TEXT}
              </span>
            </label>
            <div className={inputWrapperStyling || "w-40"}>
              {props.values?.additionalInsured?.map((insured, index) => (
                <div key={index} className={`w-100 flex flex-column`}>
                  {editIndex === index ? (
                    <Fragment>
                      <div className="mt2">
                        <Field
                          name={`additionalInsured.${index}.name`}
                          className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2 border-box"
                          placeholder="Name"
                          disabled={!isActiveEdit}
                        />
                        <Field
                          name={`additionalInsured.${index}.line1`}
                          className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2 mt2 border-box"
                          placeholder="Line 1"
                          disabled={!isActiveEdit}
                        />
                        <Field
                          name={`additionalInsured.${index}.line2`}
                          className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2 mt2 border-box"
                          placeholder="Line 2"
                          disabled={!isActiveEdit}
                        />
                        <Field
                          name={`additionalInsured.${index}.line3`}
                          className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2 mt2 border-box"
                          placeholder="Line 3"
                          disabled={!isActiveEdit}
                        />
                        <Field
                          name={`additionalInsured.${index}.locality`}
                          className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2 mt2 border-box"
                          placeholder="City"
                          disabled={!isActiveEdit}
                        />
                        <Field
                          name={`additionalInsured.${index}.region`}
                          className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2 mt2 border-box"
                          placeholder="State"
                          disabled={!isActiveEdit}
                        />
                        <Field
                          name={`additionalInsured.${index}.postalcode`}
                          className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2 mt2 border-box"
                          placeholder="Zip Code"
                          disabled={!isActiveEdit}
                        />
                        <button
                          type="button"
                          className={classNames(
                            "outline-0 pa2 w-100 f6 fw3 ba br2 mv2 border-box pointer blue",
                            {
                              "bg-washed-blue": isActiveEdit,
                              "b--blue": isActiveEdit,
                            }
                          )}
                          disabled={!isActiveEdit}
                          onClick={() => setEditIndex(null)}
                        >
                          Update
                        </button>
                      </div>
                    </Fragment>
                  ) : (
                    // This is the "edit" text or button for each item
                    <div className="flex items-center justify-between b--black-10 ba br2 pa2 mb2">
                      <div className="flex-item">
                        <span className="black fw5">
                          {insured.name || "N/A"}
                        </span>
                        &nbsp;
                        <span className="black-40">
                          {formatAddress(insured)}
                        </span>
                      </div>
                      <div className="flex-item">
                        <button
                          disabled={!isActiveEdit}
                          className={classNames("bn bg-transparent", {
                            "pointer blue": isActiveEdit,
                          })}
                          type="button"
                          onClick={() => setEditIndex(index)}
                        >
                          Edit
                        </button>{" "}
                        |
                        <button
                          disabled={!isActiveEdit}
                          className={classNames("bn bg-transparent", {
                            "pointer light-red": isActiveEdit,
                          })}
                          type="button"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <button
                type="button"
                className={classNames(
                  "outline-0 pa2 w-100 f6 fw5 b--black-10 ba br2 mt2",
                  {
                    "bg-washed-green pointer bg-transparent green b--green":
                      isActiveEdit,
                  }
                )}
                disabled={!isActiveEdit}
                onClick={() => {
                  push(additionalInsured);
                  setEditIndex(props.values?.additionalInsured?.length);
                }}
              >
                Add Additional Insured
              </button>
            </div>
          </div>
        </Fragment>
      )}
    </FieldArray>
  );
};

function _handleSubmit(values, actions) {
  actions.setSubmitting(false);
}

function prepJobCategories(classNames, jobCategories) {
  if (!classNames || !jobCategories) {
    return [];
  }

  const codes = classNames.map((className) => {
    const category = jobCategories.find(
      (category) => category.className === className
    );
    return category ? category.code : null;
  });

  return codes.filter((code) => code !== null);
}

const RequirementsForm = (props) => {
  const {
    hideName = false,
    hideJobCategories = false,
    isAdmin,
    hideBackButton = false,
    backButtonLink,
    startFromExisting,
    ...restProps
  } = props;

  const location = useLocation();
  const navigate = useNavigate(); // Get the navigate function

  let { requirementId } = useParams();

  const [isActiveEdit, setIsActiveEdit] =
    props.isActiveEdit !== undefined
      ? [props.isActiveEdit, props.setIsActiveEdit]
      : useState(requirementId === "new");

  const [isProcessing, setIsProcessing] =
    props.isProcessing !== undefined
      ? [props.isProcessing, props.setIsProcessing]
      : useState(false);

  const [data, setData] = useState(null);

  const [
    updateInsuranceRequirement,
    { loading: saveLoading, error: savingError, reset },
  ] = useMutation(UPDATE_INSURANCE_REQUIREMENT, {
    onCompleted(data) {
      const newRecordId =
        data?.updateInsuranceRequirement?.insuranceRequirement.publicId;
      navigate(`/organization/requirements/${newRecordId}`);

      reset();
      setIsActiveEdit(false);
    },
    onError(error) {
      setIsActiveEdit(false);
    },
    refetchQueries: [
      "InsuranceRequirement",
      "InsuranceRequirementJobCategories",
    ],
  });

  const {
    loading: jobCategoriesLoading,
    error: jobCategoriesError,
    data: jobCategoriesData,
  } = useQuery(INSURANCE_REQUIREMENT_JOB_CATEGORIES);

  const {
    loading,
    error,
    data: queryData,
  } = useQuery(INSURANCE_REQUIREMENT_BY_ID_QUERY, {
    variables: { id: requirementId },
    fetchPolicy: "cache-and-network",
    skip: location.state !== null,
  });

  useEffect(() => {
    if (location.state) {
      setData(location.state);
      navigate(location.pathname, { replace: true });
      setIsProcessing(false);
    } else if (!loading && !error) {
      const requirementTemplate =
        queryData?.insuranceRequirement?.externalRequirementTemplates;

      if (
        requirementTemplate &&
        requirementTemplate?.[0]?.mostRecentAudit?.status !== "DONE"
      ) {
        setIsProcessing(true);
      } else {
        setIsProcessing(false);
      }

      setData(queryData?.insuranceRequirement);
    }
  }, [navigate, location, loading, error, queryData]);

  function simplifyCoverageRules(coverageRules) {
    // Check if coverageRules is defined and has the 'edges' property
    if (!coverageRules || !coverageRules.edges) {
      return []; // Return an empty array if invalid input
    }

    return coverageRules.edges.map((edge) => edge.node);
  }

  const simplifiedCoverageRules = simplifyCoverageRules(data?.coverageRules);
  const coverageRules = mapRulesToValues(simplifiedCoverageRules);
  const coverages = getCoveragesFromRules(simplifiedCoverageRules);
  const autoCoverages = getAutoCoveragesFromRules(simplifiedCoverageRules);

  // Function to handle back navigation
  const handleBackClick = () => {
    if (backButtonLink) {
      navigate(backButtonLink);
    } else {
      navigate(`/organization/requirements`);
    }
  };

  const renderError = () => (
    <div className="br2 f6 flex items-center justify-center pa3 bg-lightest-blue navy mt3">
      <span className="lh-title ml3">
        There was an error saving the organization insurance requirements.
        Please try again.
      </span>
    </div>
  );

  function handleCoverageChange(fieldName, values, setFieldValue) {
    if (values.coverage.includes(fieldName)) {
      const nextValue = values.coverage.filter((value) => value !== fieldName);
      setFieldValue("coverage", nextValue);
    } else {
      const nextValue = [...values.coverage, fieldName];
      setFieldValue("coverage", nextValue);
    }
  }

  function handleAutoCoverageChange(fieldName, values, setFieldValue) {
    if (values.autoCoverageType.includes(fieldName)) {
      const nextValue = values.autoCoverageType.filter(
        (value) => value !== fieldName
      );
      setFieldValue("autoCoverageType", nextValue);
    } else {
      const nextValue = [...values.autoCoverageType, fieldName];
      setFieldValue("autoCoverageType", nextValue);
    }
  }

  const formInitialValues = {};

  formInitialValues.requirementName = data?.name || "";
  formInitialValues.jobCategories =
    data?.jobCategories?.edges.map((edge) => edge.node.className) || [];

  formInitialValues.coverage = coverages || [];
  formInitialValues.generalLiabilityOccurrenceLimit =
    coverageRules?.generalLiabilityOccurrenceLimit || "";
  formInitialValues.generalLiabilityGeneralAggregateLimit =
    coverageRules?.generalLiabilityGeneralAggregateLimit || "";
  formInitialValues.generalLiabilityDamageToRentedLimit =
    coverageRules?.generalLiabilityDamageToRentedLimit || "";
  formInitialValues.generalLiabilityMedExpLimit =
    coverageRules?.generalLiabilityMedExpLimit || "";
  formInitialValues.generalLiabilityPersonalInjuryLimit =
    coverageRules?.generalLiabilityPersonalInjuryLimit || "";
  formInitialValues.generalPolicyType = toCamelCase(
    coverageRules?.generalPolicyType || ""
  );
  formInitialValues.generalWaiverOfSubrogation =
    !!coverageRules?.generalWaiverOfSubrogation || null;
  formInitialValues.generalAdditionalInsured =
    !!coverageRules?.generalAdditionalInsured || null;

  formInitialValues.workersCompEachAccidentLimit =
    coverageRules?.workersCompEachAccidentLimit || "";
  formInitialValues.workersCompDiseaseEachEmployeeLimit =
    coverageRules?.workersCompDiseaseEachEmployeeLimit || "";
  formInitialValues.workersCompDiseasePolicyLimit =
    coverageRules?.workersCompDiseasePolicyLimit || "";
  formInitialValues.workersCompPolicyType = toCamelCase(
    coverageRules?.workersCompPolicyType || ""
  );
  formInitialValues.workersCompWaiverOfSubrogation =
    !!coverageRules?.workersCompWaiverOfSubrogation || null;

  formInitialValues.professionalLiabilityOccurrenceLimit =
    coverageRules?.professionalLiabilityOccurrenceLimit || "";
  formInitialValues.professionalLiabilityAggregateLimit =
    coverageRules?.professionalLiabilityAggregateLimit || "";
  formInitialValues.professionalWaiverOfSubrogation =
    !!coverageRules?.professionalWaiverOfSubrogation || null;
  formInitialValues.professionalAdditionalInsured =
    !!coverageRules?.professionalAdditionalInsured || null;

  formInitialValues.autoLiabilityCombinedSingleLimit =
    coverageRules?.autoLiabilityCombinedSingleLimit || "";
  formInitialValues.autoLiabilityBodilyInjuryPerAccidentLimit =
    coverageRules?.autoLiabilityBodilyInjuryPerAccidentLimit || "";
  formInitialValues.autoLiabilityBodilyInjuryPerPersonLimit =
    coverageRules?.autoLiabilityBodilyInjuryPerPersonLimit || "";
  formInitialValues.autoLiabilityPropertyDamageLimit =
    coverageRules?.autoLiabilityPropertyDamageLimit || "";
  formInitialValues.autoCoverageType = autoCoverages || [];
  formInitialValues.autoWaiverOfSubrogation =
    !!coverageRules?.autoWaiverOfSubrogation || null;
  formInitialValues.autoAdditionalInsured =
    !!coverageRules?.autoAdditionalInsured || null;

  formInitialValues.umbrellaLiabilityOccurrenceLimit =
    coverageRules?.umbrellaLiabilityOccurrenceLimit || "";
  formInitialValues.umbrellaLiabilityAggregateLimit =
    coverageRules?.umbrellaLiabilityAggregateLimit || "";
  formInitialValues.umbrellaPolicyType = toCamelCase(
    coverageRules?.umbrellaPolicyType || ""
  );
  formInitialValues.umbrellaWaiverOfSubrogation =
    !!coverageRules?.umbrellaWaiverOfSubrogation || null;
  formInitialValues.umbrellaAdditionalInsured =
    !!coverageRules?.umbrellaAdditionalInsured || null;

  formInitialValues.excessLiabilityOccurrenceLimit =
    coverageRules?.excessLiabilityOccurrenceLimit || "";
  formInitialValues.excessLiabilityAggregateLimit =
    coverageRules?.excessLiabilityAggregateLimit || "";
  formInitialValues.excessPolicyType = toCamelCase(
    coverageRules?.excessPolicyType || ""
  );
  formInitialValues.excessWaiverOfSubrogation =
    !!coverageRules?.excessWaiverOfSubrogation || null;
  formInitialValues.excessAdditionalInsured =
    !!coverageRules?.excessAdditionalInsured || null;

  formInitialValues.cyberLiabilityOccurrenceLimit =
    coverageRules?.cyberLiabilityOccurrenceLimit || "";
  formInitialValues.cyberLiabilityAggregateLimit =
    coverageRules?.cyberLiabilityAggregateLimit || "";
  formInitialValues.cyberWaiverOfSubrogation =
    !!coverageRules?.cyberWaiverOfSubrogation || null;
  formInitialValues.cyberAdditionalInsured =
    !!coverageRules?.cyberAdditionalInsured || null;

  formInitialValues.crimeLiabilityOccurrenceLimit =
    coverageRules?.crimeLiabilityOccurrenceLimit || "";
  formInitialValues.crimeLiabilityAggregateLimit =
    coverageRules?.crimeLiabilityAggregateLimit || "";
  formInitialValues.crimePolicyType = toCamelCase(
    coverageRules?.crimePolicyType || ""
  );
  formInitialValues.crimeWaiverOfSubrogation =
    !!coverageRules?.crimeWaiverOfSubrogation || null;
  formInitialValues.crimeAdditionalInsured =
    !!coverageRules?.crimeAdditionalInsured || null;

  formInitialValues.descriptionOfOperations =
    coverageRules?.descriptionOfOperations || "";
  formInitialValues.additionalInsured = (coverageRules?.additionalInsured &&
    JSON.parse(coverageRules?.additionalInsured)) || [additionalInsured];

  function isEmptyObject(obj) {
    return Object.values(obj).every((value) => !value);
  }

  function prepCoverageRuleValues(coverageRules) {
    const filteredAdditionalInsured = coverageRules.additionalInsured.filter(
      (insured) => !isEmptyObject(insured)
    );

    let updatedCoverageRules = { ...coverageRules };
    if (filteredAdditionalInsured.length > 0) {
      updatedCoverageRules = {
        ...coverageRules,
        additionalInsured: filteredAdditionalInsured,
      };
    }

    const coverageRuleValues = mapValuesToRules(updatedCoverageRules);
    return coverageRuleValues;
  }

  const allJobCategories =
    jobCategoriesData?.insuranceRequirementJobCategories?.allJobCategories ||
    [];

  function saveInsuranceRequirements(values) {
    updateInsuranceRequirement({
      variables: {
        publicId: requirementId,
        name: values.requirementName,
        jobCategories: prepJobCategories(
          values.jobCategories,
          allJobCategories
        ),
        coverageRules: prepCoverageRuleValues(values),
      },
    });
  }

  const handleSave =
    props.saveInsuranceRequirements || saveInsuranceRequirements;

  if (
    loading ||
    error ||
    jobCategoriesLoading ||
    jobCategoriesError ||
    isProcessing
  ) {
    return <ReactLoading type={"spin"} color={"#cccccc"} className="center" />;
  }

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        onSubmit={_handleSubmit}
        enableReinitialize={true}
      >
        {(props) => {
          const { values, isLoading, setFieldValue, handleSubmit } = props;

          const selectedJobCategories = values.jobCategories || [];
          const availableJobCategories =
            jobCategoriesData.insuranceRequirementJobCategories
              ?.unusedJobCategories;
          const availableJobClassNames = Object.values(
            availableJobCategories
          ).map((category) => category.className);

          return (
            <form onSubmit={handleSubmit}>
              {!hideBackButton && (
                <div className="flex justify-between">
                  <div className="flex-item content-center">
                    <div className="back-arrow" onClick={handleBackClick}>
                      <span className="f6 hover-bg-light-gray pointer pv2 ph3 br4">
                        ← Back
                      </span>
                    </div>
                  </div>
                  <EditButton
                    isLoading={isLoading}
                    save={handleSave}
                    setIsActiveEdit={setIsActiveEdit}
                    isActiveEdit={isActiveEdit}
                    values={values}
                    startFromExisting={
                      startFromExisting
                    }
                  />
                </div>
              )}
              {savingError ? renderError() : null}
              {startFromExisting && (
                <div className="pl4 pt4">
                <div className="br2 pa2 ba b--blue bg-washed-blue relative dib w-100 pv3 f6 measure lh-copy">
                  <b>Already have existing requirements in .pdf or .doc format?</b><br/> 
                  Click <a className="pointer blue" onClick={() => startFromExisting()}>here</a> to upload your existing requirements. We'll
                  automatically pre-populate the coverage requirements for you.
                </div>
                </div>
              )}
              
              <Fragment>
                <div className="relative dib w-100 pl4 pv3 f6">
                  <div id="requirements" className="mt3">
                    {!hideName && (
                      <div className="pb4">
                        <div className="w-100 flex items-start">
                          <label className="f6 fw5 dib w-20">
                            Requirement Name
                          </label>
                          <Field
                            name="requirementName"
                            className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2 border-box"
                            placeholder="e.g., Acme Requirements"
                            disabled={!isActiveEdit}
                          />
                        </div>
                      </div>
                    )}
                    {!hideJobCategories && (
                      <div className="pb4">
                        <div className="w-100 flex items-start">
                          <label className="f6 fw5 dib w-20">
                            Job Category
                          </label>
                          <PillInput
                            name="jobCategories"
                            selectedJobCategories={selectedJobCategories}
                            allowedValues={availableJobClassNames}
                            disabled={!isActiveEdit}
                            onChange={(newValues) => {
                              setFieldValue("jobCategories", newValues);
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="pb4">
                      <div className="w-100 flex items-start">
                        <label className="f6 fw5 dib w-20">
                          Required coverage
                        </label>
                        <div
                          role="group"
                          aria-labelledby="checkbox-group"
                          className="outline-0 w-40 f6 fw3 flex flex-wrap"
                        >
                          <div className="w-50 fl">
                            <div className="w-100">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="coverage"
                                  value="general"
                                  className="mr1 mb2"
                                  disabled={!isActiveEdit}
                                  onChange={() =>
                                    handleCoverageChange(
                                      "general",
                                      values,
                                      setFieldValue
                                    )
                                  }
                                  checked={values.coverage.includes("general")}
                                />
                                General Liability
                              </label>
                            </div>
                            <div className="w-100">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="coverage"
                                  value="workers-comp"
                                  className="mr1 mb2"
                                  disabled={!isActiveEdit}
                                  onChange={() =>
                                    handleCoverageChange(
                                      "workers-comp",
                                      values,
                                      setFieldValue
                                    )
                                  }
                                  checked={values.coverage.includes(
                                    "workers-comp"
                                  )}
                                />
                                Workers Comp
                              </label>
                            </div>
                            <div className="w-100">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="coverage"
                                  value="professional"
                                  className="mr1 mb2"
                                  disabled={!isActiveEdit}
                                  onChange={() =>
                                    handleCoverageChange(
                                      "professional",
                                      values,
                                      setFieldValue
                                    )
                                  }
                                  checked={values.coverage.includes(
                                    "professional"
                                  )}
                                />
                                Professional Liability
                              </label>
                            </div>
                            <div className="w-100">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="coverage"
                                  value="cyber"
                                  className="mr1 mb2"
                                  disabled={!isActiveEdit}
                                  onChange={() =>
                                    handleCoverageChange(
                                      "cyber",
                                      values,
                                      setFieldValue
                                    )
                                  }
                                  checked={values.coverage.includes("cyber")}
                                />
                                Cyber
                              </label>
                            </div>
                          </div>
                          <div className="w-50 fl">
                            <div className="w-100">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="coverage"
                                  value="auto"
                                  className="mr1 mb2"
                                  disabled={!isActiveEdit}
                                  onChange={() =>
                                    handleCoverageChange(
                                      "auto",
                                      values,
                                      setFieldValue
                                    )
                                  }
                                  checked={values.coverage.includes("auto")}
                                />
                                Auto Liability
                              </label>
                            </div>
                            <div className="w-100">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="coverage"
                                  value="umbrella"
                                  className="mr1 mb2"
                                  disabled={!isActiveEdit}
                                  onChange={() =>
                                    handleCoverageChange(
                                      "umbrella",
                                      values,
                                      setFieldValue
                                    )
                                  }
                                  checked={values.coverage.includes("umbrella")}
                                />
                                Umbrella Liability
                              </label>
                            </div>
                            <div className="w-100">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="coverage"
                                  value="excess"
                                  className="mr1 mb2"
                                  disabled={!isActiveEdit}
                                  onChange={() =>
                                    handleCoverageChange(
                                      "excess",
                                      values,
                                      setFieldValue
                                    )
                                  }
                                  checked={values.coverage.includes("excess")}
                                />
                                Excess Liability
                              </label>
                            </div>
                            <div className="w-100">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="coverage"
                                  value="crime"
                                  className="mr1 mb2"
                                  disabled={!isActiveEdit}
                                  onChange={() =>
                                    handleCoverageChange(
                                      "crime",
                                      values,
                                      setFieldValue
                                    )
                                  }
                                  checked={values.coverage.includes("crime")}
                                />
                                Crime
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {values?.coverage?.includes("general") && (
                      <div className="ba br2 ph2 pv4 mb4 b--black-10">
                        <h4 className="dark-gray mt0 pb3">General Liability</h4>
                        <div className="pb4">
                          <div className="w-100 flex items-start">
                            <label className="f6 fw5 dib w-20">
                              Policy Type
                            </label>
                            <div
                              role="group"
                              aria-labelledby="radio-group"
                              className="outline-0 w-30 f6 fw3"
                            >
                              <div className="w-100 mb2">
                                <label>
                                  <Field
                                    type="radio"
                                    name="generalPolicyType"
                                    value="occurrence"
                                    className="mr1"
                                    disabled={!isActiveEdit}
                                    checked={
                                      values.generalPolicyType === "occurrence"
                                    }
                                  />
                                  Occurrence
                                </label>
                              </div>
                              <div className="w-100 mb2">
                                <label>
                                  <Field
                                    type="radio"
                                    name="generalPolicyType"
                                    value="claimsMade"
                                    className="mr1"
                                    disabled={!isActiveEdit}
                                    checked={
                                      values.generalPolicyType === "claimsMade"
                                    }
                                  />
                                  Claims-made
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Waiver of subrogation
                            </label>
                            <Field
                              type="checkbox"
                              name="generalWaiverOfSubrogation"
                              value="generalWaiverOfSubrogation"
                              disabled={!isActiveEdit}
                              checked={values.generalWaiverOfSubrogation}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Additional insured
                            </label>
                            <Field
                              type="checkbox"
                              name="generalAdditionalInsured"
                              value="generalAdditionalInsured"
                              disabled={!isActiveEdit}
                              checked={values.generalAdditionalInsured}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-center">
                            <label
                              htmlFor="generalLiabilityOccurrenceLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Per Occurrence Limit
                            </label>
                            <Field
                              name="generalLiabilityOccurrenceLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-center">
                            <label
                              htmlFor="generalLiabilityGeneralAggregateLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Aggregate Limit
                            </label>
                            <Field
                              name="generalLiabilityGeneralAggregateLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-center">
                            <label
                              htmlFor="generalLiabilityDamageToRentedLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Damage to Rented Limit
                            </label>
                            <Field
                              name="generalLiabilityDamageToRentedLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="50000">$50k</option>
                              <option value="100000">$100k</option>
                              <option value="300000">$300k</option>
                              <option value="500000">$500k</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-center">
                            <label
                              htmlFor="generalLiabilityMedExpLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Medical Expense Limit
                            </label>
                            <Field
                              name="generalLiabilityMedExpLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="5000">$5k</option>
                              <option value="10000">$10k</option>
                              <option value="15000">$15k</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-center">
                            <label
                              htmlFor="generalLiabilityPersonalInjuryLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Personal Injury Limit
                            </label>
                            <Field
                              name="generalLiabilityPersonalInjuryLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>
                      </div>
                    )}

                    {values?.coverage?.includes("workers-comp") && (
                      <div className="ba br2 ph2 pv4 mb4 b--black-10">
                        <h4 className="dark-gray mt0 pb3">
                          Workers Compensation
                        </h4>

                        <div className="pb4">
                          <div className="w-100 flex items-start">
                            <label className="f6 fw5 dib w-20">
                              Policy Type
                            </label>
                            <div
                              role="group"
                              aria-labelledby="radio-group"
                              className="outline-0 w-30 f6 fw3"
                            >
                              <div className="w-100 mb2">
                                <label>
                                  <Field
                                    type="radio"
                                    name="workersCompPolicyType"
                                    value="perStatute"
                                    className="mr1"
                                    disabled={!isActiveEdit}
                                    checked={
                                      values.workersCompPolicyType ===
                                      "perStatute"
                                    }
                                  />
                                  Per-statute
                                </label>
                              </div>
                              <div className="w-100 mb2">
                                <label>
                                  <Field
                                    type="radio"
                                    name="workersCompPolicyType"
                                    value="other"
                                    className="mr1"
                                    disabled={!isActiveEdit}
                                    checked={
                                      values.workersCompPolicyType === "other"
                                    }
                                  />
                                  Other
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Waiver of subrogation
                            </label>
                            <Field
                              type="checkbox"
                              name="workersCompWaiverOfSubrogation"
                              value="workersCompWaiverOfSubrogation"
                              disabled={!isActiveEdit}
                              checked={values.workersCompWaiverOfSubrogation}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-start">
                            <label
                              htmlFor="workersCompEachAccidentLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Each Accident Limit
                            </label>
                            <Field
                              name="workersCompEachAccidentLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-start">
                            <label
                              htmlFor="workersCompDiseaseEachEmployeeLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Disease Each Employee Limit
                            </label>
                            <Field
                              name="workersCompDiseaseEachEmployeeLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-start">
                            <label
                              htmlFor="workersCompDiseasePolicyLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Disease Policy Limit
                            </label>
                            <Field
                              name="workersCompDiseasePolicyLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>
                      </div>
                    )}

                    {values?.coverage?.includes("professional") && (
                      <div className="ba br2 ph2 pv4 mb4 b--black-10">
                        <h4 className="dark-gray mt0 pb3">
                          Professional Liability
                        </h4>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Waiver of subrogation
                            </label>
                            <Field
                              type="checkbox"
                              name="professionalWaiverOfSubrogation"
                              value="professionalWaiverOfSubrogation"
                              disabled={!isActiveEdit}
                              checked={values.professionalWaiverOfSubrogation}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Additional insured
                            </label>
                            <Field
                              type="checkbox"
                              name="professionalAdditionalInsured"
                              value="professionalAdditionalInsured"
                              disabled={!isActiveEdit}
                              checked={values.professionalAdditionalInsured}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-start">
                            <label
                              htmlFor="professionalLiabilityOccurrenceLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Per Occurence Limit
                            </label>
                            <Field
                              name="professionalLiabilityOccurrenceLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-start">
                            <label
                              htmlFor="professionalLiabilityAggregateLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Aggregate Limit
                            </label>
                            <Field
                              name="professionalLiabilityAggregateLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>
                      </div>
                    )}

                    {values?.coverage?.includes("auto") && (
                      <div className="ba br2 ph2 pv4 mb4 b--black-10">
                        <h4 className="dark-gray mt0 pb3">Auto Liability</h4>

                        <div className="pb4">
                          <div className="w-100 flex items-start">
                            <label className="f6 fw5 dib w-20">
                              Policy Type
                            </label>
                            <div
                              role="group"
                              aria-labelledby="checkbox-group"
                              className="outline-0 w-40 f6 fw3 flex flex-wrap"
                            >
                              <div className="w-50 fl">
                                <div className="w-100">
                                  <label>
                                    <Field
                                      type="checkbox"
                                      name="autoCoverageType"
                                      value="ownedAuto"
                                      className="mr1 mb2"
                                      disabled={!isActiveEdit}
                                      onChange={() =>
                                        handleAutoCoverageChange(
                                          "ownedAuto",
                                          values,
                                          setFieldValue
                                        )
                                      }
                                      checked={values.autoCoverageType.includes(
                                        "ownedAuto"
                                      )}
                                    />
                                    Owned Autos Only
                                  </label>
                                </div>
                                <div className="w-100">
                                  <label>
                                    <Field
                                      type="checkbox"
                                      name="autoCoverageType"
                                      value="hiredAuto"
                                      className="mr1 mb2"
                                      disabled={!isActiveEdit}
                                      onChange={() =>
                                        handleAutoCoverageChange(
                                          "hiredAuto",
                                          values,
                                          setFieldValue
                                        )
                                      }
                                      checked={values.autoCoverageType.includes(
                                        "hiredAuto"
                                      )}
                                    />
                                    Hired Autos Only
                                  </label>
                                </div>
                                <div className="w-100">
                                  <label>
                                    <Field
                                      type="checkbox"
                                      name="autoCoverageType"
                                      value="scheduledAuto"
                                      className="mr1 mb2"
                                      disabled={!isActiveEdit}
                                      onChange={() =>
                                        handleAutoCoverageChange(
                                          "scheduledAuto",
                                          values,
                                          setFieldValue
                                        )
                                      }
                                      checked={values.autoCoverageType.includes(
                                        "scheduledAuto"
                                      )}
                                    />
                                    Scheduled Autos
                                  </label>
                                </div>
                                <div className="w-100">
                                  <label>
                                    <Field
                                      type="checkbox"
                                      name="autoCoverageType"
                                      value="nonOwnedAuto"
                                      className="mr1 mb2"
                                      disabled={!isActiveEdit}
                                      onChange={() =>
                                        handleAutoCoverageChange(
                                          "nonOwnedAuto",
                                          values,
                                          setFieldValue
                                        )
                                      }
                                      checked={values.autoCoverageType.includes(
                                        "nonOwnedAuto"
                                      )}
                                    />
                                    Non-owned Autos Only
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Waiver of subrogation
                            </label>
                            <Field
                              type="checkbox"
                              name="autoWaiverOfSubrogation"
                              value="autoWaiverOfSubrogation"
                              disabled={!isActiveEdit}
                              checked={values.autoWaiverOfSubrogation}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Additional insured
                            </label>
                            <Field
                              type="checkbox"
                              name="autoAdditionalInsured"
                              value="autoAdditionalInsured"
                              disabled={!isActiveEdit}
                              checked={values.autoAdditionalInsured}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex flex-items-start">
                            <label
                              htmlFor="autoLiabilityCombinedSingleLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Combined Single Limit
                            </label>
                            <Field
                              name="autoLiabilityCombinedSingleLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex flex-items-start">
                            <label
                              htmlFor="autoLiabilityBodilyInjuryPerAccidentLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Bodily Injury Per Accident Limit
                            </label>
                            <Field
                              name="autoLiabilityBodilyInjuryPerAccidentLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex flex-items-start">
                            <label
                              htmlFor="autoLiabilityBodilyInjuryPerPersonLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Bodily Injury Per Person Limit
                            </label>
                            <Field
                              name="autoLiabilityBodilyInjuryPerPersonLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex flex-items-start">
                            <label
                              htmlFor="autoLiabilityPropertyDamageLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Property Damage Limit
                            </label>
                            <Field
                              name="autoLiabilityPropertyDamageLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>
                      </div>
                    )}

                    {values?.coverage?.includes("umbrella") && (
                      <div className="ba br2 ph2 pv4 mb4 b--black-10">
                        <h4 className="dark-gray mt0 pb3">
                          Umbrella Liability
                        </h4>

                        <div className="pb4">
                          <div className="w-100 flex items-start">
                            <label className="f6 fw5 dib w-20">
                              Policy Type
                            </label>
                            <div
                              role="group"
                              aria-labelledby="radio-group"
                              className="outline-0 w-30 f6 fw3"
                            >
                              <div className="w-100 mb2">
                                <label>
                                  <Field
                                    type="radio"
                                    name="umbrellaPolicyType"
                                    value="occurrence"
                                    className="mr1"
                                    disabled={!isActiveEdit}
                                  />
                                  Occurrence
                                </label>
                              </div>
                              <div className="w-100 mb2">
                                <label>
                                  <Field
                                    type="radio"
                                    name="umbrellaPolicyType"
                                    value="claimsMade"
                                    className="mr1"
                                    disabled={!isActiveEdit}
                                  />
                                  Claims-made
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Waiver of subrogation
                            </label>
                            <Field
                              type="checkbox"
                              name="umbrellaWaiverOfSubrogation"
                              value="umbrellaWaiverOfSubrogation"
                              disabled={!isActiveEdit}
                              checked={values.umbrellaWaiverOfSubrogation}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Additional insured
                            </label>
                            <Field
                              type="checkbox"
                              name="umbrellaAdditionalInsured"
                              value="umbrellaAdditionalInsured"
                              disabled={!isActiveEdit}
                              checked={values.umbrellaAdditionalInsured}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-start">
                            <label
                              htmlFor="umbrellaLiabilityOccurrenceLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Per Occurrence Limit
                            </label>
                            <Field
                              name="umbrellaLiabilityOccurrenceLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                              <option value="6000000">$6mil</option>
                              <option value="8000000">$8mil</option>
                              <option value="10000000">$10mil</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-start">
                            <label
                              htmlFor="umbrellaLiabilityAggregateLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Aggregate Limit
                            </label>
                            <Field
                              name="umbrellaLiabilityAggregateLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                              <option value="6000000">$6mil</option>
                              <option value="8000000">$8mil</option>
                              <option value="10000000">$10mil</option>
                            </Field>
                          </span>
                        </div>
                      </div>
                    )}

                    {values?.coverage?.includes("excess") && (
                      <div className="ba br2 ph2 pv4 mb4 b--black-10">
                        <h4 className="dark-gray mt0 pb3">Excess Liability</h4>

                        <div className="pb4">
                          <div className="w-100 flex items-start">
                            <label className="f6 fw5 dib w-20">
                              Excess Policy Type
                            </label>
                            <div
                              role="group"
                              aria-labelledby="radio-group"
                              className="outline-0 w-30 f6 fw3"
                            >
                              <div className="w-100 mb2">
                                <label>
                                  <Field
                                    type="radio"
                                    name="excessPolicyType"
                                    value="occurrence"
                                    className="mr1"
                                    disabled={!isActiveEdit}
                                  />
                                  Occurrence
                                </label>
                              </div>
                              <div className="w-100 mb2">
                                <label>
                                  <Field
                                    type="radio"
                                    name="excessPolicyType"
                                    value="claimsMade"
                                    className="mr1"
                                    disabled={!isActiveEdit}
                                  />
                                  Claims-made
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Waiver of subrogation
                            </label>
                            <Field
                              type="checkbox"
                              name="excessWaiverOfSubrogation"
                              value="excessWaiverOfSubrogation"
                              disabled={!isActiveEdit}
                              checked={values.excessWaiverOfSubrogation}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Additional insured
                            </label>
                            <Field
                              type="checkbox"
                              name="excessAdditionalInsured"
                              value="excessAdditionalInsured"
                              disabled={!isActiveEdit}
                              checked={values.excessAdditionalInsured}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-start">
                            <label
                              htmlFor="excessLiabilityOccurrenceLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Per Occurrence Limit
                            </label>
                            <Field
                              name="excessLiabilityOccurrenceLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                              <option value="6000000">$6mil</option>
                              <option value="8000000">$8mil</option>
                              <option value="10000000">$10mil</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-start">
                            <label
                              htmlFor="excessLiabilityAggregateLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Aggregate Limit
                            </label>
                            <Field
                              name="excessLiabilityAggregateLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                              <option value="6000000">$6mil</option>
                              <option value="8000000">$8mil</option>
                              <option value="10000000">$10mil</option>
                            </Field>
                          </span>
                        </div>
                      </div>
                    )}

                    {values?.coverage?.includes("cyber") && (
                      <div className="ba br2 ph2 pv4 mb4 b--black-10">
                        <h4 className="dark-gray mt0 pb3">Cyber Liability</h4>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Waiver of subrogation
                            </label>
                            <Field
                              type="checkbox"
                              name="cyberWaiverOfSubrogation"
                              value="cyberWaiverOfSubrogation"
                              disabled={!isActiveEdit}
                              checked={values.cyberWaiverOfSubrogation}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Additional insured
                            </label>
                            <Field
                              type="checkbox"
                              name="cyberAdditionalInsured"
                              value="cyberAdditionalInsured"
                              disabled={!isActiveEdit}
                              checked={values.cyberAdditionalInsured}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-start">
                            <label
                              htmlFor="cyberLiabilityOccurrenceLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Per Occurrence Limit
                            </label>
                            <Field
                              name="cyberLiabilityOccurrenceLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-start">
                            <label
                              htmlFor="cyberLiabilityAggregateLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Aggregate Limit
                            </label>
                            <Field
                              name="cyberLiabilityAggregateLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>
                      </div>
                    )}

                    {values?.coverage?.includes("crime") && (
                      <div className="ba br2 ph2 pv4 mb4 b--black-10">
                        <h4 className="dark-gray mt0 pb3">
                          Commercial Crime Liability
                        </h4>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Waiver of subrogation
                            </label>
                            <Field
                              type="checkbox"
                              name="crimeWaiverOfSubrogation"
                              value="crimeWaiverOfSubrogation"
                              disabled={!isActiveEdit}
                              checked={values.crimeWaiverOfSubrogation}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <div className="w-100">
                            <label className="f6 fw5 dib w-20">
                              Additional insured
                            </label>
                            <Field
                              type="checkbox"
                              name="crimeAdditionalInsured"
                              value="crimeAdditionalInsured"
                              disabled={!isActiveEdit}
                              checked={values.crimeAdditionalInsured}
                            />
                          </div>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-start">
                            <label
                              htmlFor="crimeLiabilityOccurrenceLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Per Occurrence Limit
                            </label>
                            <Field
                              name="crimeLiabilityOccurrenceLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>

                        <div className="pb4">
                          <span className="w-100 flex items-start">
                            <label
                              htmlFor="crimeLiabilityAggregateLimit"
                              className="f6 fw5 dib w-20"
                            >
                              Aggregate Limit
                            </label>
                            <Field
                              name="crimeLiabilityAggregateLimit"
                              component="select"
                              className="outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                              disabled={!isActiveEdit}
                            >
                              <option value="">Select limit</option>
                              <option value="500000">$500k</option>
                              <option value="1000000">$1mil</option>
                              <option value="1500000">$1.5mil</option>
                              <option value="2000000">$2mil</option>
                              <option value="3000000">$3mil</option>
                              <option value="5000000">$5mil</option>
                            </Field>
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="ba br2 ph2 pv4 mb4 b--black-10">
                      <h4 className="dark-gray mt0 pb3">
                        Description of Operations
                      </h4>
                      <span className="w-100 flex items-start">
                        <label
                          htmlFor="description"
                          className="f6 fw5 dib w-20"
                        >
                          Description
                        </label>
                        <Field
                          className="w-40 h5 outline-0 pa2 w-30 f6 fw3 ba b--black-10 br2"
                          placeholder="e.g., Certificate holder is an additional insured via written contract. Acme Inc. are added as additional insured on a primary non-contributory basis. Alternate endorsement applies. Waiver of subrogation where applicable."
                          name="descriptionOfOperations"
                          component="textarea"
                          disabled={!isActiveEdit}
                        />
                      </span>
                    </div>

                    <div className="ba br2 ph2 pv4 mb4 b--black-10">
                      <h4 className="dark-gray mt0 pb3">Additional Insured</h4>
                      <AdditionalInsuredForm
                        values={values}
                        isActiveEdit={isActiveEdit}
                        editIndexDefault={0}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export { RequirementsForm, AdditionalInsuredForm, prepJobCategories };
