import Pusher from 'pusher-js';

// Create a singleton Pusher instance
let pusherInstance = null;

export const getPusherInstance = () => {
  if (!pusherInstance) {
    pusherInstance = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER || "",
    });
  }
  return pusherInstance;
};