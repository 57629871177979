import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  INSURANCE_REQUIREMENTS_BY_ENTITY_ID_QUERY,
  ENTITY_BY_ID_QUERY,
} from "./queries";
import {
  UPDATE_ENTITY_DETAILS,
  UPDATE_ENTITY_LEVEL_INSURANCE_REQUIREMENTS,
} from "./mutations";
import MainWrapper from "../../components/MainWrapper";
import { Tabs, Tab } from "../../components/Tab";
import GeneralTabContent from "./General";
import InsuranceRequirementsTable from "../OrganizationPage/SettingsPage/Requirements";
import { mapValuesToRules } from "../CertificateReviewPage/helpers";
import { INSURANCE_REQUIREMENT_JOB_CATEGORIES } from "../OrganizationPage/SettingsPage/queries";
import { prepJobCategories } from "../OrganizationPage/SettingsPage/Requirement";
import AddUnstructuredRequirementModal from "./AddUnstructuredRequirementModal";

import { usePusherChannel } from "../../utils/pusher";
import { pusherEvents } from "../../constants/pusherEvents";
import { getPusherInstance } from "../../utils/pusherInstance";

function Entity(props) {
  const { isTestView, isAdmin } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { entityId } = useParams();
  const requirementId = location.pathname.split("/requirements/")[1];

  const [isActiveEdit, setIsActiveEdit] = useState(requirementId === "new");

  useEffect(() => {
    setIsActiveEdit(requirementId === "new");
  }, [requirementId]);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const {
    loading,
    error,
    data: queryData,
  } = useQuery(ENTITY_BY_ID_QUERY, {
    variables: { id: entityId },
    fetchPolicy: "cache-and-network",
    skip: location.state !== null,
  });

  useEffect(() => {
    if (location.state) {
      setData(location.state);
      navigate(location.pathname, { replace: true });
    } else if (!loading && !error) {
      setData(queryData.entity);
    }
  }, [navigate, location, loading, error, queryData]);

  const {
    loading: jobCategoriesLoading,
    error: jobCategoriesError,
    data: jobCategoriesData,
  } = useQuery(INSURANCE_REQUIREMENT_JOB_CATEGORIES);

  const allJobCategories =
    jobCategoriesData?.insuranceRequirementJobCategories?.allJobCategories ||
    [];

  const [updateEntityDetails, { loading: saving }] = useMutation(
    UPDATE_ENTITY_DETAILS,
    {
      onCompleted(data) {
        setIsActiveEdit(false);
        setData(data.updateEntityDetails.entity);
        setSuccessMessage(data.updateEntityDetails.message);
      },
      onError() {
        setErrorMessage("Failed to update entity.");
        setIsActiveEdit(false);
      },
      refetchQueries: [
        { query: ENTITY_BY_ID_QUERY, variables: { id: entityId } },
      ],
    }
  );

  const renderSuccess = (message) => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-green green mv3">
      <span className="lh-title ml3">{message}</span>
    </div>
  );

  const renderError = (message) => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red mv3">
      <span className="lh-title ml3">{message}</span>
    </div>
  );

  const [
    updateInsuranceRequirement,
    { loading: saveLoading, error: savingError, reset },
  ] = useMutation(UPDATE_ENTITY_LEVEL_INSURANCE_REQUIREMENTS, {
    onCompleted(data) {
      const newRecordId =
        data?.updateEntityLevelInsuranceRequirements?.insuranceRequirement
          .publicId;
      navigate(`/entities/${entityId}/requirements/${newRecordId}`);

      reset();
      setIsActiveEdit(false);
    },
    onError(error) {
      setIsActiveEdit(false);
    },
    refetchQueries: [
      "InsuranceRequirementsByEntityId",
      "InsuranceRequirementJobCategories",
    ],
  });

  function isEmptyObject(obj) {
    return Object.values(obj).every((value) => !value);
  }

  function prepCoverageRuleValues(coverageRules) {
    const filteredAdditionalInsured = coverageRules.additionalInsured.filter(
      (insured) => !isEmptyObject(insured)
    );

    let updatedCoverageRules = { ...coverageRules };
    if (filteredAdditionalInsured.length > 0) {
      updatedCoverageRules = {
        ...coverageRules,
        additionalInsured: filteredAdditionalInsured,
      };
    }

    const coverageRuleValues = mapValuesToRules(updatedCoverageRules);
    return coverageRuleValues;
  }

  function handleSaveInsuranceRequirements(values) {
    updateInsuranceRequirement({
      variables: {
        entityId: entityId,
        jobCategories: prepJobCategories(
          values.jobCategories,
          allJobCategories
        ),
        coverageRules: prepCoverageRuleValues(values),
      },
    });
  }

  const {
    loading: requirementsLoading,
    error: requirementsError,
    data: requirementsData,
    refetch: refetchRequirements,
  } = useQuery(INSURANCE_REQUIREMENTS_BY_ENTITY_ID_QUERY, {
    variables: {
      entityId: entityId,
    },
  });

  const orgId = props.orgId;

  usePusherChannel(
    getPusherInstance(),
    `${orgId}-${pusherEvents.INSURANCE_REQUIREMENT_CREATED}-${entityId}`,
    pusherEvents.INSURANCE_REQUIREMENT_CREATED,
    function (data) {
      if (data && data?.id) {
        navigate(`/entities/${entityId}/requirements/${data?.id}`);
      }
    }
  );

  usePusherChannel(
    getPusherInstance(),
    `${orgId}-${pusherEvents.INSURANCE_REQUIREMENT_STAGED}-${entityId}`,
    pusherEvents.INSURANCE_REQUIREMENT_STAGED,
    function (data) {
      window.location.reload();
    }
  );

  const UNSTRUCTURED_REQUIREMENT_UPLOAD_PATH = "/api/v1/bulk/requirement";

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(false);
  const [contractor, setContractor] = useState();

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/entities');
  };

  // Determine if the current path is a detail page
  const isDetailPage = location.pathname.includes('/requirements/') && requirementId !== undefined;

  const renderPage = () => (
    <>
      <MainWrapper isTestView={isTestView}>
        {successMessage && renderSuccess(successMessage)}
        {errorMessage && renderError(errorMessage)}

        {!isDetailPage && ( // Conditionally render the back button
          <div className="flex-item content-center absolute top-1 left-1">
            <div className="back-arrow" onClick={handleBackClick}>
              <span className="f6 hover-bg-light-gray pointer pv2 ph3 br4">← Back</span>
            </div>
          </div>
        )}

        <article className="dib w-100 mt3">
          <Tabs>
            <Tab
              label="General"
              routePath="general"
              navPath={`/entities/${entityId}/general`}
            >
              <GeneralTabContent
                data={data}
                isAdmin={isAdmin}
                isActiveEdit={isActiveEdit}
                setIsActiveEdit={setIsActiveEdit}
                updateEntityDetails={updateEntityDetails}
                saving={saving}
              />
            </Tab>
            <Tab
              label="Insurance Requirements"
              routePath="requirements/*"
              navPath={`/entities/${entityId}/requirements`}
            >
              <>
                <InsuranceRequirementsTable
                  isActiveEdit={isActiveEdit}
                  setIsActiveEdit={setIsActiveEdit}
                  requirementsData={requirementsData?.insuranceRequirements}
                  requirementsLoading={requirementsLoading}
                  createNewRequirementLink={`/entities/${entityId}/requirements/new`}
                  backButtonLink={`/entities/${entityId}/requirements`}
                  saveInsuranceRequirements={handleSaveInsuranceRequirements}
                  hideName={true}
                  refetchRequirements={refetchRequirements}
                  startFromExisting={() => {
                    setModalIsOpen(true);
                  }}
                  isProcessing={isProcessing}
                  setIsProcessing={setIsProcessing}
                />
              </>
            </Tab>
          </Tabs>
        </article>
      </MainWrapper>

      <AddUnstructuredRequirementModal
        closeModal={() => {
          setModalIsOpen(false);
        }}
        setSuccessMessage={setUploadSuccessMessage}
        setErrorMessage={setUploadErrorMessage}
        setContractor={setContractor}
        token={props.token}
        modalIsOpen={modalIsOpen}
        contentLabel={"Add existing insurance requirements"}
        title={"Existing insurance requirements"}
        uploadOnly={true}
        targetURL={UNSTRUCTURED_REQUIREMENT_UPLOAD_PATH}
        inputName={"unstructured_requirement"}
        labelIdle={
          'Drag & Drop your existing requirements or <span class="filepond--label-action">Browse</span>'
        }
        entityId={entityId}
        requirementId={requirementId}
        onComplete={() => setIsProcessing(true)}
      />
    </>
  );

  const renderLoading = () => (
    <MainWrapper isTestView={isTestView}>
      <div>Loading...</div>
    </MainWrapper>
  );

  return data ? renderPage() : renderLoading();
}

export default Entity;
