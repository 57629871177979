import { gql } from "@apollo/client";

export const ENTITY_QUERY = gql`
  query Entities($cursor: String) {
    entities(first:25, after: $cursor, sort:CREATED_DESC) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          publicId
          name
          created
          address {
            line1
            line2
            locality
            region
            postalcode
          }
          requiredCoverage
        }
      }
    }
  }
`;

export const INSURANCE_REQUIREMENTS_BY_ENTITY_ID_QUERY = gql`
  query InsuranceRequirementsByEntityId($entityId: String!) {
    insuranceRequirements(entityId: $entityId) {
      created
      publicId
      name
      jobCategories {
        edges {
          node {
            className
          }
        }
      }
      coverageRules {
        edges {
          node {
            id
            ruleName
            ruleJson
          }
        }
      }
    }
  }
`;

export const ENTITY_BY_ID_QUERY = gql`
  query EntityById($id: ID!) {
    entity(id: $id) {
      publicId
      name
      created
      additionalInsuredMsg
      address {
        line1
        line2
        line3
        locality
        region
        postalcode
      }
      requiredCoverage
    }
  }
`;
