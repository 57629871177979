import React, { useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import classNames from "classnames";

import Modal from "react-modal";

import moment from "moment";

import MainWrapper from "../../components/MainWrapper";

Modal.setAppElement("#root");

import { ENTITY_QUERY } from "./queries";

function GetStarted() {
  return (
    <div>
      <p className="lh-copy f6 fw3 black-70">
        To start, you'll need to add entities which represent your contracting
        entities. Contact us at support@1099policy.com to get started.
      </p>
    </div>
  );
}

function EntityTable(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const cursor = searchParams.get("starting_after");

  const { loading, data, fetchMore } = useQuery(ENTITY_QUERY, {
    variables: { cursor },
    notifyOnNetworkStatusChange: true,
  });

  const renderPagination = (pageInfo) => {
    const hasNextPage = pageInfo?.hasNextPage;

    return (
      <div className="tr dtc">
        <div className="dib">
          <button
            type="button"
            onClick={() => fetchNextPage(data)}
            disabled={!hasNextPage}
            className={classNames(
              "fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml2 f7 lh-title border-box dib",
              { "bg-animate hover-bg-light-gray": hasNextPage }
            )}
          >
            Next
          </button>
          <button
            type="button"
            onClick={() => navigate(-1)}
            disabled={cursor == null}
            className={classNames(
              "fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title border-box dib",
              { "bg-animate hover-bg-light-gray": cursor != null }
            )}
          >
            Previous
          </button>
        </div>
      </div>
    );
  };

  const fetchNextPage = (data) => {
    const cursor = data.entities?.pageInfo.endCursor;
    
    fetchMore({
      variables: {
        cursor: cursor,
      },
    });
    navigate(`/entities?starting_after=${cursor}`)
  };

  const getAddress = (address) => {
    if (!address) return "";
    return `${address.line1}, ${address.locality}, ${address.region} ${address.postalcode}`;
  };

  const renderRows = (data) => {
    const rows = data.entities.edges.map((row) => (
      <tr
        onClick={() => {
          navigate(`${row.node.publicId}/general`, { state: row.node });
        }}
        className="bb b--light-gray hover-bg-washed-blue pointer"
        key={row.node.publicId}
      >
        <td className="tl ph2 f7">
          {moment(row.node.created).format("MMM D, YYYY")}
        </td>
        <td className="tl ph2 pv3 f7">{row.node.name}</td>
        <td className="tl ph2 pv3 f7">{getAddress(row.node.address)}</td>
        <td className="tl ph2 pv3 f7">{row.node.requiredCoverage?.join(", ")}</td>
      </tr>
    ));

    return rows;
  };

  const renderTotalResults = (totalCount) => {
    return totalCount ? (
      <div className="tl dt">
        <div className="dib">
          <div className="f6">
            <b>{totalCount}</b> results
          </div>
        </div>
      </div>
    ) : null;
  };

  const renderTable = (data) => {
    if (data.entities.edges.length > 0) {
      const pageInfo = data.entities.pageInfo;
      const totalCount = data.entities.totalCount;
      return (
        <div>
          <table className="w-100 ba b--light-gray collapse ph2 mv4">
            <thead>
              <tr className="bb bw1 b--black-10">
                <th className="tl pv3 ph2 fw5 f7 ttu">Created on</th>
                <th className="tl w-50 ph2 fw5 f7 ttu">Entity Name</th>
                <th className="tl pv3 fw5 f7 ttu">Address</th>
                <th className="tl pv3 fw5 f7 ttu">Required Coverage</th>
              </tr>
            </thead>
            <tbody>{renderRows(data)}</tbody>
          </table>
          <div className="db dt w-100 v-mid">
            {renderTotalResults(totalCount)}
            {renderPagination(pageInfo)}
          </div>
        </div>
      );
    } else {
      return <GetStarted openModal={props.openModal} />;
    }
  };

  return loading ? (
    <ReactLoading type={"spin"} color={"#cccccc"} className="center" />
  ) : (
    renderTable(data)
  );
}

function EntitiesPage(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <MainWrapper isTestView={props.isTestView}>
      <header className="mb3">
        <h2 className="fw3 dark-gray mt0 mb4">Entities</h2>
      </header>
      <EntityTable openModal={() => setModalIsOpen(true)} />
    </MainWrapper>
  );
}

export default EntitiesPage;
