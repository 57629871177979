import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Routes, Route, NavLink } from 'react-router-dom';

export const Tabs = ({ children, useInternalState = false }) => {
  const location = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const tabRefs = useRef([]);

  // Effect to update active tab based on URL or internal state
  useEffect(() => {
    if (!useInternalState) {
      const currentPath = location.pathname;
      const activeChildIndex = React.Children.toArray(children).findIndex((child) =>
        currentPath.startsWith(child.props.navPath)
      );

      if (activeChildIndex !== -1) {
        setActiveTabIndex(activeChildIndex);
        updateIndicator(currentPath);
      }
    } else {
      // Initialize internal tab indicator
      const navPath = React.Children.toArray(children)[activeTabIndex].props.navPath;
      updateIndicator(navPath);
    }
  }, [location, children, useInternalState, activeTabIndex]);

  // Change tab function to handle both modes
  const changeTab = (newTab, index) => {
    if (useInternalState) {
      setActiveTabIndex(index);
      updateIndicator(newTab);
    }
  };

  // Update indicator position
  const updateIndicator = (currentPath) => {
    const activeTabRef = tabRefs.current.find(
      (ref) => ref && currentPath.includes(ref.dataset.url)
    );

    if (activeTabRef) {
      setIndicatorStyle({
        left: activeTabRef.offsetLeft,
        width: activeTabRef.offsetWidth,
      });
    }
  };

  return (
    <div className="tabs">
      <ul className="list flex ma0 pa0 bb b--black-10 relative">
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            isActive: index === activeTabIndex,
            ref: (el) => (tabRefs.current[index] = el),
            ...(useInternalState && {
              onClick: () => changeTab(child.props.navPath, index),
            }),
            useInternalState,
          })
        )}
        <div className="indicator" style={indicatorStyle} />
      </ul>
      <div className="tab-content mt4">
        {useInternalState ? (
          // Render content based on internal state
          React.Children.toArray(children)[activeTabIndex].props.children
        ) : (
          // Render Routes for URL-based navigation
          <Routes>
            {React.Children.map(children, (child) => (
              <Route
                key={child.props.routePath}
                path={child.props.routePath}
                element={child.props.children}
              />
            ))}
          </Routes>
        )}
      </div>
    </div>
  );
};

export const Tab = React.forwardRef(
  ({ label, navPath, isActive, onClick, useInternalState }, ref) => {
    return (
      <li
        className={`pointer pv2 ph3 ${isActive ? 'active-tab' : ''}`}
        ref={ref}
        data-label={label}
        data-url={navPath}
        {...(useInternalState && { onClick })}
      >
        {useInternalState ? (
          <span className="pv2 ph3 link no-underline dark-gray">{label}</span>
        ) : (
          <NavLink
            to={navPath}
            className="pv2 ph3 link no-underline dark-gray hover-black-50"
            end
          >
            {label}
          </NavLink>
        )}
      </li>
    );
  }
);
