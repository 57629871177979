// utils/pusher.js
import { useEffect } from "react";

export const usePusherChannel = (pusher, channelName, eventName, callback) => {
  useEffect(() => {
    if (!pusher) return;

    const channel = pusher.subscribe(channelName);
    
    // Handle connection states
    pusher.connection.bind('state_change', ({ current }) => {
      if (current === 'disconnected') {
        console.log('Disconnected from Pusher');
        // Optionally show a UI notification to user
      } else if (current === 'connected') {
        console.log('Connected to Pusher');
      }
    });

    channel.bind(eventName, callback);

    return () => {
      channel.unbind(eventName, callback);
      pusher.unsubscribe(channelName);
    };
  }, [pusher, channelName, eventName, callback]);
};
