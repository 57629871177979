import { gql } from "@apollo/client";

export const USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      email
      twoFaEnabled
      needsOtpCheck
      feature {
        edges {
          node {
            key
          }
        }
      }
      roles {
        edges {
          node {
            name
          }
        }
      }
      organizations {
        edges {
          node {
            organization {
              id
              publicId
              created
              active
              invitations
              creatorIqApikey
              withholdPremium
              billToEmailPrimary
              billToEmailCc
              isPurchaseOrderWorkflowEnabled
              certificateHolderName
              certificateHolderAddress {
                line1
                line2
                line3
                locality
                region
                postalcode
              }
              name
              apikeys {
                edges {
                  node {
                    publicKey
                    secretKey
                  }
                }
              }
              users {
                edges {
                  node {
                    user {
                      id
                      email
                    }
                    created
                    organizationRole {
                      role {
                        name
                      }
                    }
                  }
                }
              }
            }
            organizationRole {
              role {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const ORGANIZATION_BY_ID_QUERY = gql`
  query OrganizationByID($id: ID!) {
    organization(id: $id) {
      id
      publicId
      active
      created
      bulkImportTemplateUrl
      invitations
      creatorIqApikey
      withholdPremium
      billToEmailPrimary
      billToEmailCc
      isPurchaseOrderWorkflowEnabled
      certificateHolderName
      companyAddress {
        line1
        line2
        line3
        locality
        region
        postalcode
      }
      certificateHolderAddress {
        line1
        line2
        line3
        locality
        region
        postalcode
      }
      name
      apikeys {
        edges {
          node {
            publicKey
            secretKey
          }
        }
      }
      users {
        edges {
          node {
            user {
              id
              email
            }
            created
            organizationRole {
              role {
                name
              }
            }
          }
        }
      }
    }
  }
`;
