import React from "react";

import ReactLoading from "react-loading";

function LoadingSpinner(props) {
  return (
    <ReactLoading
      type={"spin"}
      color={"#cccccc"}
      width={props.width || 14}
      height={props.height || 14}
    />
  );
}

export default LoadingSpinner;
