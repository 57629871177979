import React, {useEffect, useState, useMemo} from 'react';

import ReactLoading from 'react-loading';
import Modal from 'react-modal';

import {useLocation, useNavigate, useParams} from 'react-router-dom';

import { useQuery, useMutation } from "@apollo/client";

import classNames from 'classnames'

import { Formik, Form } from 'formik';

import { shortCoverageNames, getPolicies, resolveRedacted } from './helpers';

import ContractorSideNav from './ContractorSideNav';
import ApplicationsTable from './ApplicationsTable';
import ActiveCoverageTable from './ActiveCoverageTable';
import CertificatesTable from './CertificatesTable';
import WorkHistoryTable from './WorkHistoryTable';

import MainWrapper from '../../components/MainWrapper';

import { createValidationSchema } from './formValidation';

import {
  CONTRACTOR_BY_ID_QUERY
} from './queries';

import { 
  UPDATE_CONTRACTOR_DETAILS
} from './mutations'

Modal.setAppElement('#root');

let tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

let oneYear = new Date()
oneYear.setDate(oneYear.getDate() + 365) 

function deduplicateByJobId(items) {
  const itemMap = new Map();
  items.forEach(item => {
    const jobId = item?.node?.job.publicId;
    if (jobId) {
      if (!itemMap.has(jobId) || itemMap.get(jobId)?.node?.effectiveDate < item?.node?.effectiveDate) {
        itemMap.set(jobId, item);
      }
    }
  });
  return Array.from(itemMap.values());
}

function buildJobHistory(quotes, assignments){
  // Filter out quotes where voided is true
  let filteredQuotes = quotes.filter(quote => !quote?.node?.voided);
  let filteredAssignments = assignments.filter(assignment => 
    assignment?.node?.cancelled ? true : !assignment?.node?.voided
  );
  
  // De-duplicate quotes and assignments
  const deduplicatedQuotes = deduplicateByJobId(filteredQuotes);
  const deduplicatedAssignments = deduplicateByJobId(filteredAssignments);

  // Combine quotes and assignments
  let quotes_and_assignments = [...deduplicatedQuotes, ...deduplicatedAssignments];

  function dateDescFn(a,b){
    if (a?.node?.effectiveDate < b?.node?.effectiveDate){
      return 1
    }
    if (a?.node?.effectiveDate > b?.node?.effectiveDate){
      return -1
    }
    return 0
  }
  
  quotes_and_assignments.sort(dateDescFn)
  return quotes_and_assignments
}

function EditButton(props){
  const {
    resetForm,
    errors,
    isLoading,
    isSubmitting,
    setIsActiveEdit,
  } = props
  
  const loadingSpinner = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} width={14} height={14} />
  )

  const renderEdit = () => {
    return (
      <div className='absolute top-2 right-2'>
      <button 
        id="editContractorDetails"
        type="button" 
        onClick={() => setIsActiveEdit(true)}
        className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box">
        Edit
      </button>
      </div>    
    )
  }

  const renderCancelSave = () => {
    const isDisabled = Object.keys(errors).length > 0
    return (
      <div className='absolute top-2 right-2'>
        <button 
        id="cancelEditContractorDetails"
        type="button" 
        onClick={() => {
          setIsActiveEdit(false)
          resetForm()
        }}
        className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box">
          Cancel
        </button>
        <button 
        id="saveedit"
        type="submit" 
        disabled={isDisabled || isLoading || isSubmitting}
        className={classNames(
          "fr outline-0 pointer br2 ba b--black-20  pa2 ml1 f7 lh-title border-box",
          { 'bg-blue white': !isDisabled },
          { 'bg-black-10 black': isDisabled })
          }>
          {isLoading ? loadingSpinner() : 'Save'}
        </button>
      </div>
    )
  }

  return (
    props.isActiveEdit ? renderCancelSave() : renderEdit()
  )
}

function ContractorMain(props){
  let [markExceptions, setMarkExceptions] = useState([])

  const {
    data, 
    isAdmin, 
    values, 
    setValues, 
    errors, 
    setSuccessMessage,
    setNewAssignments
  } = props
  
  const newApplicationUrls = props.newApplicationUrls
  
  const NOT_STARTED = "Not started."
  const IN_PROGRESS = "In progress"
  const COMPLETE = "Complete"
  const INELIGIBLE = "Ineligible"

  const APPROVED = "APPROVED"
  const DENIED = "DENIED"
  
  let allQuotes = data?.quotes?.edges
  let allAssignments = data?.assignments?.edges
  let allExternalCertificates = data?.externalCertificates?.edges
  
  let allCertificateAudits = allExternalCertificates.flatMap(
    (externalCertificate) => externalCertificate.node.certificateAudits.edges.map(
      (auditEdge) => auditEdge.node
    )
  )

  const filteredCertificates = allCertificateAudits.filter(
    (certificate) => certificate?.status === APPROVED || certificate?.status === DENIED
  );

  const newApplicationRecords = props.newApplicationRecords
  const insuranceApplications = getApplications(newApplicationRecords ? allQuotes.concat(newApplicationRecords) : allQuotes)
  
  const policies = getPolicies(allQuotes)
  
  const newAssignments = props.newAssignments
  const jobsArray = useMemo(() => 
    buildJobHistory(allQuotes, allAssignments), 
    [allQuotes, allAssignments, newAssignments]);
  
  //Update formik values
  useEffect(() => {
    setValues({...values, jobs: jobsArray})
  }, [jobsArray])
  
  const confirmedCancelledPolicies = props.confirmedCancelledPolicies
  
  function getApplicationStatus(quote, insuranceApplication){
    let status = NOT_STARTED

    const quoteId = quote?.node?.publicId    
    const policy = quote?.node?.policy?.edges[0]?.node

    const newAppUrl = newApplicationUrls.length > 0 ? newApplicationUrls.find(record => record['quoteId'] === quoteId) : null;
    const applicationStarted = !newAppUrl && insuranceApplication?.applicationStarted

    const isException = markExceptions.length > 0 ? markExceptions.find(record => record['quoteId'] === quoteId) : null;
    
    if (!insuranceApplication?.isEligible && !isException){
      status = INELIGIBLE
    }

    if (policy){
      status = COMPLETE 
    } else if(applicationStarted){
      status = IN_PROGRESS
    } 

    return status
  }

  function getApplications(quotes){
    let applicationList = [];

    applicationList = quotes
      .map(obj => {
        const newObj = {
          ...obj?.node.insuranceApplication,
          ['status']: getApplicationStatus(obj, obj?.node.insuranceApplication),
          ['quoteId']: obj?.node.publicId,
          ['quoteEndDate']: obj?.node.endDate,
          ['jobCategory']: obj?.node.job?.jobCategory?.className,
          ['coverageTypes']: shortCoverageNames(obj?.node?.coverageType?.edges),
        }
        
        const newAppUrl = newApplicationUrls.find(record => record['quoteId'] === obj?.node.publicId)
        
        if(newAppUrl){
          newObj['url'] = newAppUrl['url']
        }
  
        return newObj
      })
      .filter(value => value.status !== COMPLETE)
      .filter(value => value.url !== null && value.url !== undefined)
      .filter(value => value !== null && value !== undefined)
    
    return applicationList
  }
  
  return (
    <div className="flex flex-column w-100">
      <div className="flex mb3">
        <div className="w-25 pr3">
          <ContractorSideNav
            {...props}
            isActiveEdit={props.isActiveEdit} 
            data={props.data}
          />
        </div>
        <div className="w-75">
          <ApplicationsTable 
            insuranceApplications={insuranceApplications} 
            isAdmin={isAdmin} 
            {...props}
          />
          <ActiveCoverageTable 
            policies={policies} 
            isAdmin={isAdmin} 
            confirmedCancelledPolicies={confirmedCancelledPolicies} 
            {...props}
          />
          <CertificatesTable 
            filteredCertificates={filteredCertificates}
          />
        </div>
      </div>
      <div className="w-100">
        <WorkHistoryTable
          jobsArray={jobsArray}
          policies={policies}
          errors={errors}
          setSuccessMessage={setSuccessMessage}
          setNewAssignments={setNewAssignments}
          {...props}
        />
      </div>
    </div>
  )
}

const renderSuccess = (message) => (
  <div className="br2 f6 flex items-center justify-center pa2 bg-washed-green green mv3">
    <span className="lh-title ml3">{message}</span>
  </div>
)

const renderError = (message) => (
  <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red mv3">
    <span className="lh-title ml3">{message}</span>
  </div>
)

function hasActivePolicy(quotes){
  return quotes.edges?.some(quote => quote?.node?.policy?.edges[0]?.node?.isActive)
}

function hasAnApplicationInProgress(quotes){
  return quotes.edges?.some(quote => quote?.node?.insuranceApplication?.applicationStarted)
}

function initValues(data){
  let allQuotes = data?.quotes?.edges
  let allAssignments = data?.assignments?.edges

  return {
    contractorId: data?.publicId,
    firstName: data?.firstName,
    middleName: data?.middleName,
    lastName: data?.lastName,
    email: data?.email,
    line1: data?.address?.line1 || "",
    line2: data?.address?.line2 || "",
    line3: data?.address?.line3 || "",
    locality: data?.address?.locality || "",
    region: data?.address?.region,
    postalcode: data?.address?.postalcode || "",
    poContactName: data?.poContactName || "",
    poContactEmail: data?.poContactEmail || "",
    withholdPremium: data?.withholdPremium || "",
    jobs: buildJobHistory(allQuotes, allAssignments),
  }
}

function Contractor(props){
  const { isTestView, isAdmin, token } = props
  
  const [isActiveEdit, setIsActiveEdit] = useState(false)
  const [newAssignments, setNewAssignments] = useState()
  
  // Note: newApplicationUrls vs. newApplicationRecords 
  //
  // newApplicationUrls is used for expireRenew mutation results
  // newApplicationRecords is used for createQuote mutation results
  //
  const [newApplicationRecords, setNewApplicationRecords] = useState()
  const [newApplicationUrls, setNewApplicationUrls] = useState([])
  
  const [successMessage, setSuccessMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [confirmedCancelledPolicies, setConfirmedCancelledPolicies] = useState([])

  const location = useLocation();
  const navigate = useNavigate();

  const { contractorId } = useParams();

  const [data, setData] = useState(null);
  
  const { loading, error, data: queryData } = useQuery(CONTRACTOR_BY_ID_QUERY, {
    variables: { id: contractorId }, // replace with your actual contractor ID
    fetchPolicy: 'cache-and-network', // or 'cache-first' for initial load from cache then network
    skip: location.state !== null, // skip the query if location.state is not undefined
  });

  useEffect(() => {
    if (location.state) {
      setData(location.state);    
      // Navigate to the same path without state to clear it
      navigate(location.pathname, { replace: true });
    }
    else if (!loading && !error) {
      const resolvedContractor = resolveRedacted(queryData?.contractor, 
        JSON.parse(queryData?.contractor?.unredactedDict));
      
      setData(resolvedContractor);
    }
  }, [navigate, location, loading, error, queryData]);

  const [updateContractorDetails, {loading: saving, error: savingError, reset}] = useMutation(UPDATE_CONTRACTOR_DETAILS, {
    onCompleted() {
      reset()
      setIsActiveEdit(false)
      navigate(location.pathname, {state: null});
    },
    onError(error){
      setIsActiveEdit(false)
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CONTRACTOR_BY_ID_QUERY,
        variables: { id: contractorId }, // Specify the variables for the query
      }
    ],
  })

  function saveContractorDetails(values){
    const customMetadata = {
      purchaseOrderContactName: values.poContactName,
      purchaseOrderContactEmail: values.poContactEmail,
      purchaseOrderId: values.poId,
    }

    const contractor = {
      id: values.contractorId,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      email: values.email,
      line1: values.line1,
      line2: values.line2,
      line3: values.line3,
      locality: values.locality,
      region: values.region,
      postalcode: values.postalcode,
      customMetadata: customMetadata
    }

    const job = {
      id: values.jobs[0].node?.job?.publicId,
      name: values.jobs[0].node?.job?.name,
      wage: parseInt(values.jobs[0].node?.job?.wage)
    }
    
    const entity = {
      id: values.jobs[0].node?.job?.entity?.publicId,
      name: values.jobs[0].node?.job?.entity?.name,
    }

    const quote = {
      id: values.jobs[0].node?.publicId,
      effectiveDate: values.jobs[0].node?.effectiveDate,
      endDate: values.jobs[0].node?.endDate,
      workState: values.jobs[0].node?.workState,
    }
    
    updateContractorDetails({
      variables: {
        contractor: contractor,
        job: job,
        entity: entity,
        quote: quote,
      }
    })
  }
  let page;

  const renderLoading = () => (
    <>
      <MainWrapper isTestView={isTestView}>
        <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
      </MainWrapper>
    </>
  )

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/contractors')
  };


  const renderPage = () => (
    <>
      <MainWrapper isTestView={isTestView}>
        {successMessage && renderSuccess(successMessage)}
        {errorMessage && renderError(errorMessage)}

        <div className="flex-item content-center absolute top-1 left-1">
          <div className="back-arrow" onClick={handleBackClick}>
            <span className="f6 hover-bg-light-gray pointer pv2 ph3 br4">← Back</span>
          </div>
        </div>

        <article className="dib w-100 mt3">
          <Formik
            initialValues={initValues(data)}
            validationSchema={createValidationSchema(initValues(data))}
            onSubmit={(values, {setSubmitting}) => {
              saveContractorDetails(values)
              setSubmitting(true)
            }}
          > 
            {(formikProps) => (
              <Form onSubmit={formikProps.handleSubmit}>
                {(isAdmin && !hasAnApplicationInProgress(data?.quotes) && !hasActivePolicy(data?.quotes)) &&
                  <EditButton
                    isLoading={saving}
                    saveContractorDetails={saveContractorDetails}
                    setIsActiveEdit={setIsActiveEdit} 
                    isActiveEdit={isActiveEdit}
                    values={formikProps.values}
                    {...formikProps}
                  />
                }
                <ContractorMain 
                  {...props}
                  {...formikProps}
                  isAdmin={isAdmin}
                  newAssignments={newAssignments}
                  newApplicationRecords={newApplicationRecords}
                  newApplicationUrls={newApplicationUrls}
                  setNewApplicationUrls={setNewApplicationUrls}
                  setNewAssignments={setNewAssignments}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  setSuccessMessage={setSuccessMessage}
                  isActiveEdit={isActiveEdit}
                  data={data} 
                  openModal={() => setModalIsOpen(true)}
                  confirmedCancelledPolicies={confirmedCancelledPolicies}
                />
              </Form>
            )}
          </Formik>
        </article>
      </MainWrapper>
    </>
  )
  
  page = data ? renderPage() : renderLoading()

  return (
    <>
      {page}
    </>
  )
}
export default Contractor